/* eslint-disable */
module.exports = {
  ButtonTransparentStates: {
    primary: "primary",
    secondary: "secondary",
    tertiary: "tertiary",
    favorites: "favorites",
    connect: "connect"
  },
  IconTypes: {
    telegram: "telegram",
    questionmark: "questionmark",
    settings: "settings",
    star: "star",
    starfilled: "starfilled",
    trash: "trash",
    search: "search",
    context: "context",
    eraseexpand: "eraseexpand",
    close: "close",
    Chains: {
      BNB: "BNB",
      ETH: "ETH",
      SEPOLIA: "ETH"
    },
    profile: "profile",
    diamond: "diamond",
    diamondCrossed: "diamond-crossed",
    believers: "believers",
  },
  IconStates: {
    default: "Default",
    hover: "Hover",
    light: "Light"
  },
  PlatformTypes: {
    desktop: "Desktop",
    mobile: "Mobile"
  },
  TextStatesStyles: {
    ButtonFull: {
      desktop: {
        default: 'intelonemono-medium color-satin-linen font-m',
        hover: 'intelonemono-medium color-irish-coffee font-m',
        active: 'intelonemono-medium color-sepia-skin font-m',
      },
      mobile: {
        default: 'intelonemono-medium color-satin-linen font-s',
        hover: 'intelonemono-medium color-irish-coffee font-s',
        active: 'intelonemono-medium color-sepia-skin font-s',
      },
      getClass: function(platform, hover, active) {
        if(platform.mobile) {
          return  active?this.mobile.active:
                  hover?this.mobile.hover:this.mobile.default;
        }
        if(platform.desktop) {
          return  active?this.desktop.active:
                  hover?this.desktop.hover:this.desktop.default;
        }
      }
    },
    ButtonTransparent: {
      desktop: {
        primary: {
          default: 'intelonemono-medium color-sepia-skin font- ',
          hover: 'intelonemono-medium color-irish-coffee font-m',
          active: 'intelonemono-medium color-irish-coffee font-m',
          red: 'intelonemono-medium color-cedar-chest font-m',
          green: 'intelonemono-medium color-jade font-m',
        },
        secondary: {
          default: 'intelonemono-medium color-sepia-skin font-m',
          hover: 'intelonemono-medium color-irish-coffee font-m',
          active: 'intelonemono-medium color-irish-coffee font-m'
        },
        tertiary: {
          default: 'intelonemono-medium color-sepia-skin font-m',
          hover: 'intelonemono-medium color-irish-coffee font-m',
          active: 'intelonemono-medium color-irish-coffee font-m',
          red: 'intelonemono-medium color-cedar-chest font-m',
          green: 'intelonemono-medium color-jade font-m'
        },
        favorites: {
          default: 'intelonemono-medium color-sepia-skin font-m',
          hover: 'intelonemono-medium color-irish-coffee font-m',
          active: 'intelonemono-medium color-irish-coffee font-m'
        },
        connect: {
          default: 'intelonemono-medium color-sepia-skin font-l',
          hover: 'intelonemono-medium color-irish-coffee font-l',
          active: 'intelonemono-medium color-irish-coffee font-l',
          red: 'intelonemono-medium color-cedar-chest font-l',
          green: 'intelonemono-medium color-jade font-l'
        }
      },
      mobile: {
        primary: {
          default: 'intelonemono-medium color-sepia-skin font-m',
          hover: 'intelonemono-medium color-irish-coffee font-m',
          active: 'intelonemono-medium color-irish-coffee font-m'
        },
        secondary: {
          default: 'intelonemono-medium color-sepia-skin font-xs',
          hover: 'intelonemono-medium color-irish-coffee font-xs',
          active: 'intelonemono-medium color-irish-coffee font-xs'
        },
        tertiary: {
          default: 'intelonemono-medium color-sepia-skin font-xs',
          hover: 'intelonemono-medium color-irish-coffee font-xs',
          active: 'intelonemono-medium color-irish-coffee font-xs',
          red: 'intelonemono-medium color-cedar-chest font-xs',
          green: 'intelonemono-medium color-jade font-xs'
        },
        favorites: {
          default: 'intelonemono-medium color-sepia-skin font-xs',
          hover: 'intelonemono-medium color-irish-coffee font-xs',
          active: 'intelonemono-medium color-irish-coffee font-xs'
        },
        connect: {
          default: 'intelonemono-medium color-sepia-skin font-m',
          hover: 'intelonemono-medium color-irish-coffee font-m',
          active: 'intelonemono-medium color-irish-coffee font-m',
          red: 'intelonemono-medium color-cedar-chest font-m',
          green: 'intelonemono-medium color-jade font-m'
        }
      },
      getClass: function(platform, state, hover, active, color = null) {
        if(platform.mobile) {
          return  active?this.mobile[state].active:
                  hover?this.mobile[state].hover:
                  color?this.mobile[state][color]:this.mobile[state].default;
        }
        if(platform.desktop) {
          return  active?this.desktop[state].active:
                  hover?this.desktop[state].hover:
                  color?this.desktop[state][color]:this.desktop[state].default;
        }
      }
    },
    BaseText: {
      Color: {
        default: "default",
        red: "red",
        green: "green",
        dark: "dark",
        light: "light"
      },
      Wrap: {
        full: "full",
        truncated: "truncated",
        wrap: "wrap"
      },
      desktop: {
        default: 'intelonemono-medium color-sepia-skin font-m',
        hover: 'intelonemono-medium color-sepia-skin font-m',
        active: 'intelonemono-medium color-sepia-skin font-m',
        small: 'intelonemono-medium color-sepia-skin font-s'
      },
      mobile: {
        default: 'intelonemono-medium color-sepia-skin font-xs',
        hover: 'intelonemono-medium color-sepia-skin font-xs',
        active: 'intelonemono-medium color-sepia-skin font-xs',
        small: 'intelonemono-medium color-sepia-skin font-xs'
      },
      getClass: function(platform, tablet = false, hover = false, active = false, small = false) {
        small = (small != "force")?(small != null?true:false):small;
        if(platform.mobile) {
          return  active?this.mobile.active:
                  hover?this.mobile.hover:
                  small&&!tablet?this.mobile.small:this.mobile.default;
        }
        if(platform.desktop) {
          return  active?this.desktop.active:
                  hover?this.desktop.hover:
                  small == "force"?this.desktop.small:this.desktop.default;
        }
      }
    },
    DisconnectedFTT: {
      desktop: 'intelonemono-medium color-sepia-skin font-xl',
      mobile: 'intelonemono-medium color-sepia-skin font-l'
    },
    InputSearch: {
      desktop: "intelonemono-medium color-sepia-skin font-m",
      mobile: "intelonemono-medium color-sepia-skin font-s"
    }
  },
  Percentages: {
    Type: {
      zerofullrange: "zerofullrange",
      fullrange: "fullrange",
      takeprofit: "takeprofit",
      stoploss: "stoploss"
    }
  },
  Slider: {
    Type: {
      full: "full",
      settings: "settings"
    }
  },
  OrderHistory: {
    Type: {
      limit: "limit",
      manual: "manual"
    }
  },
  OrderRunning: {
    LimitType: {
      buy: "buy",
      sell: "sell"
    }
  },
  MainViewStatus: {
    Content: {
      Header: {
        Wallets: {
          name: "wallets",
          Default: "default",
          ImportSelect: "importselect",
          ImportShowField: "importshowfield",
          Deleted: "deleted",
          WithdrawBase: "withdrawbase",
        },
        Orders: {
          name: "orders",
          Default: "default"
        }
      },
      WalletRow: {
        View: "view",
        SelectForImport: "selectforimport",
        Deleted: "deleted",
      },
    },
    View: {
      Chart: "chart",
      Wallets: "wallets",
      Orders: "orders",
      Base: "base",
      Snipe: "snipe"
    }
  },
  Popup: {
    Content: {
      Warning: "warning",
      Settings: "settings"
    }
  },
  Theme: {
    black: {
      dark: '#000000',
      light: '#000000'
    },
    bon_jour: {
      dark: '#15161a',
      light: '#e4e2da'
    },
    bon_jour_2: {
      dark: 'rgba(21, 22, 26, 0.902)',
      light: 'rgba(228, 226, 218, 0.902)'
    },
    cedar_chest: {
      dark: '#B65CA7',
      light: '#cd5f4a'
    },
    irish_coffee: {
      dark: '#7FAFCF',
      light: '#644020'
    },
    jade: {
      dark: '#4FB484',
      light: '#4e9883'
    },
    satin_linen: {
      dark: '#15161a',
      light: '#e4e2d9'
    },
    sepia_skin: {
      dark: '#4466A9',
      light: '#91623e'
    },
    sepia_skin_2: {
      dark: '#4466A9',
      light: '#92633e'
    },
    blue_link: {
      dark: '#7FAFCF',
      light: '#3B8CC7'
    },
    filter: {
      dark: 'invert(39%) sepia(12%) saturate(2403%) hue-rotate(181deg) brightness(94%) contrast(92%)',
      light: 'invert(40%) sepia(25%) saturate(901%) hue-rotate(344deg) brightness(96%) contrast(88%)'
    },
    filter_active: {
      dark: 'invert(78%) sepia(9%) saturate(1762%) hue-rotate(172deg) brightness(88%) contrast(83%)',
      light: 'invert(24%) sepia(67%) saturate(473%) hue-rotate(347deg) brightness(90%) contrast(90%)'
    },
    filter_hollow: {
      dark: 'invert(5%) sepia(7%) saturate(1743%) hue-rotate(191deg) brightness(88%) contrast(91%)',
      light: 'invert(93%) sepia(3%) saturate(452%) hue-rotate(11deg) brightness(102%) contrast(85%)'
    }
  },
  getIconTypeClass: function(type) {
    return {
      telegram: type == this.IconTypes.telegram,
      questionmark: type == this.IconTypes.questionmark,
      settings: type == this.IconTypes.settings,
      star: type == this.IconTypes.star,
      starfilled: type == this.IconTypes.starfilled,
      trash: type == this.IconTypes.trash,
      search: type == this.IconTypes.search,
      context: type == this.IconTypes.context,
      eraseexpand: type == this.IconTypes.eraseexpand,
      close: type == this.IconTypes.close,
      profile: type == this.IconTypes.profile,
      diamond: type == this.IconTypes.diamond,
      diamondCrossed: type == this.IconTypes.diamondCrossed,
      believers: type == this.IconTypes.believers,
    }
  },
  getIconPath: function(type, state, platform, light, custom = false) {
    if(custom) return custom;
    else return `Platform=${platform.mobile?this.PlatformTypes.mobile:this.PlatformTypes.desktop},State=${light&&type==this.IconTypes.close?this.IconStates.light:state}${type}`;
  },
  getButtonTransparentStateClassName: function(state) {
    return {
      primary: state == this.ButtonTransparentStates.primary,
      secondary: state == this.ButtonTransparentStates.secondary,
      tertiary: state == this.ButtonTransparentStates.tertiary,
      favorites: state == this.ButtonTransparentStates.favorites,
      connect: state == this.ButtonTransparentStates.connect
    }
  }
}