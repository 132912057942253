<template>
 <div :absolute="absolute" class="window-bar" :class="{
    mobile: computedScreenSize < 2,
    desktop: computedScreenSize >= 2,
    absolute: absolute != null
  }">
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')

export default {
  name: 'WindowBar',
  components: {
  },
  inject: injects.getInjects(),
  props: ["absolute"],
}
</script>
<style scoped>
.window-bar {
  background-color: var(--sepia-skin-2);
  width:100%;
  left: 0;
  position: relative;
  top: 0;
}

.window-bar.mobile {
  height: 100%;
}
.window-bar.desktop {
  height: 28px;
}
.window-bar.absolute {
  position: absolute;
}
</style>