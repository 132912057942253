<template>
  <div :active="active" :gallery="gallery" :inputactive="inputactive" :state="state" class="slidercomp" :class="[classPlatform]">
    <div class="overlap-group" :class="[classPlatform]">
      <div class="slider" :class="[classPlatform,classState]">
        <div v-if="gallery != null" class="gallery" :class="[gallery, classPlatform]">
            <ul class="cards" :class="[gallery, classPlatform]">
                <li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li>
            </ul>
        </div>
        <div v-if="gallery != null" class="drag-proxy" :class="gallery"></div>
      </div>
      <div class="center"
      :class="[classPlatform,classTablet,this.inputactive == null?'events-none':'',this.wide != null?'wide':'']">
        <input-comp v-if="this.inputactive != null" placeh="0" class="inputslider" :class="inputClass"
          :name="name"
          :value="value"
          @inputHandler="inputFieldHandler"
          :chainEvent="chainEvent"
          :chainEventArgs="chainEventArgs"
          :clickoutside="clickoutside" />
        <text-comp v-if="this.inputactive == null"
        class="prevent-select"
        :class="[classPlatform.mobile?'padding-zero':'']"
        :text="labelval!=null?this[labelval]:'0'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      </div>
    </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')
import InputComp from './InputComp.vue';
import TextComp from '../basic/TextComp.vue';

export default {
  name: 'slidercomp',
  components: {
    InputComp,
    TextComp
  },
  inject: injects.getInjects(),
  props: ["active","state","inputactive","gallery","labelval","value","name","chainEvent","chainEventArgs", "clickoutside", "wide"],
  data() {
    return {
      states: states
    }
  },
  computed: {
    classState() {
      return this.state;
    },
    inputClass() {
      return this.classPlatform.desktop?states.TextStatesStyles.BaseText.desktop.default:states.TextStatesStyles.BaseText.mobile.default;
    }
  }
}
</script>
<style scoped>
.slidercomp {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
}
.slidercomp.mobile {
  height: 20px;
  padding: 2px 0px 2px 0px;
}
.slidercomp.desktop {
  height: 28px;
  padding: 2px 0px 2px 0px;
}
.overlap-group {
  height: 100%;
  position: relative;
  width: 100%;
}
.slider {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--sepia-skin-2);
  border-top-style: solid;
  border-top-width: 1px;
  height: 100%;
  width: 100%;
}
.slider.settings {
  border-left-style: solid;
  border-left-width: 1px;
}
.center {
  background-color: var(--bon-jour);
  border: 1px solid;
  border-color: var(--sepia-skin);
  border-radius: 1px;
  position: absolute;
  top: -2px;
  z-index: 2;
  display: flex;
  align-content: center;
  justify-content: center;
}
.center .inputslider {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.center .inputslider.mobile {
  padding-bottom: 0px;
}
.center.desktop {
  height: 28px;
  left: calc(50% - 22px);
  width: 44px;
}
.center.mobile {
  height: 20px;
  left: calc(50% - 15px);
  width: 30px;
}

.center.mobile.wide {
  width: calc(40%);
  left: calc(50% - (40% / 2));
}
.center.wide, .center.tablet.wide{
  width: calc(25%);
  left: calc(50% - (25% / 2));
}
.gallery {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.gallery.mobile {
  padding: 0px 2px;
}
.gallery.desktop {
  padding: 0px 3px;
}
.cards {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.cards li {
  list-style: none;
    padding: 0;
    width: 6px;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 2px;
}
</style>
