<template>
 <div :br="br" :active="active" :small="small" :text="text" :align="align" :wrap="wrap" :color="color" class="textcomp" :class="[classPlatform,classWrap, classBR,classAlign,complexTextCheck?'complex':'']">
      <span class="smaller-text" :class="[classPlatform, classTextState, classColor, classWrap, classAlign,complexTextCheck?'complex':'']">
        {{complexTextCheck?text.text[0]:text}}<sub v-if="complexTextCheck" :class="[classPlatform]">{{text.text[1]}}</sub>{{complexTextCheck?text.text[2]:''}}
      </span>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

export default {
  name: 'textcomp',
  components: {
  },
  inject: injects.getInjects(),
  props: ["active","text", "wrap", "color", "small", "align",'br'],
  computed: {
    complexTextCheck() {
      return this.text&&this.text.text? true: false;
    },
    classBR() {
      return {
        br: this.br != null
      }
    },
    classAlign() {
      return this.align != null?this.align:"";
    },
    classColor() {
      return this.color;
    },
    classWrap() {
      return {
        fullwrap: this.wrap == states.TextStatesStyles.BaseText.Wrap.full,
        truncated: this.wrap == states.TextStatesStyles.BaseText.Wrap.truncated,
        wrap: this.wrap == states.TextStatesStyles.BaseText.Wrap.wrap
      }
    },
    classTextState() {
      return states.TextStatesStyles.BaseText.getClass(this.classPlatform,this.classTablet,this.hover,this.active != null?true:false, this.small);
    }
  }
}
</script>
<style scoped>
.textcomp {
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 10px;
  position: relative;
  width: fit-content;
}
.textcomp.truncated {
  overflow: hidden;
  justify-content: flex-start;
  width: inherit;
}
.textcomp.mobile {
  padding: 0px 3px 0px;
}
.textcomp.desktop {
  padding: 0px 6px 0px;
}
.nopadding-left-right .textcomp {
  padding-left: 0px;
  padding-right: 0px;
}
.textcomp.br.mobile {
  margin-bottom: 3px;
}
.textcomp.br.desktop {
  margin-bottom: 10px;
}
.smaller-text {
  position: relative;
  text-align: center;
  width: fit-content;
  letter-spacing: 0;
  text-transform: uppercase;
  white-space: nowrap;
}
.smaller-text.complex sub.mobile{
  font-size: var(--font-size-xxs) !important;
}
.smaller-text.complex sub.desktop{
  font-size: var(--font-size-xs) !important;
}
.smaller-text.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
}
.smaller-text.wrap {
  white-space: normal;
  overflow-wrap: anywhere;
}

.smaller-text.default {
  color: var(--sepia-skin);
}
.smaller-text.red {
  color: var(--cedar-chest)
}
.smaller-text.green {
  color: var(--jade);
}
.smaller-text.dark {
  color: var(--irish-coffee);
}
.smaller-text.light {
  color: var(--bon-jour);
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.complex.desktop {
  height: 16px;
}
.complex.mobile {
  height: 10px;
}
</style>
