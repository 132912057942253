/* eslint-disable */
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Draggable from "gsap/Draggable";

globalThis.lastValues = [0, 0, 0, 0, 0, 0];
globalThis.increments = [0, 0, 0, 0, 0, 0];
gsap.registerPlugin(ScrollTrigger, Draggable);

const init = (component) => {
  const vm = {
    component: component,
  };

  // set initial state of items
  gsap.set('.cards.buy li', {xPercent: 2800, opacity: 0, scale: 0});
  gsap.set('.cards.tp li', {xPercent: 2800, opacity: 0, scale: 0});
  gsap.set('.cards.sl li', {xPercent: 2800, opacity: 0, scale: 0});
  gsap.set('.cards.bp li', {xPercent: 2800, opacity: 0, scale: 0});
  gsap.set('.cards.gas li', {xPercent: 2500, opacity: 0, scale: 0});
  gsap.set('.cards.slip li', {xPercent: 2500, opacity: 0, scale: 0});

  const spacingSCR = 0.035;
  const snapTime = gsap.utils.snap(spacingSCR);

  const animateFunc = (element) => {
    const tl = gsap.timeline();
    tl.fromTo(
      element,
      { scale: 0.8, opacity: 1, backgroundColor: 'var(--sepia-skin2)' },
      { scale: 0.8, opacity: 1, backgroundColor: 'var(--sepia-skin)', zIndex: 1, duration: 0.5, yoyo: true, repeat: 1, ease: 'power1.in', immediateRender: false }
    )
    .fromTo(element, { xPercent: 2800 }, { xPercent: -2800, duration: 1, ease: 'none', immediateRender: false }, 0);
    return tl;
  };

  const buildSeamlessLoop = (cards, spacing, animateFunc) => {
    const rawSequence = gsap.timeline({ paused: true });
    const seamlessLoop = gsap.timeline({
      paused: true,
      repeat: -1,
      onRepeat() {
        this._time === this._dur && (this._tTime += this._dur - 0.01);
      },
      onReverseComplete() {
        this.totalTime(this.rawTime() + this.duration() * 100);
      },
    });

    const cycleDuration = spacing * cards.length;
    let dur;

    cards.concat(cards).concat(cards).forEach((card, i) => {
      const anim = animateFunc(cards[i % cards.length]);
      rawSequence.add(anim, i * spacing);
      dur || (dur = anim.duration());
    });

    seamlessLoop.fromTo(
      rawSequence,
      { time: cycleDuration + dur / 2 },
      { time: `+=${cycleDuration}`, duration: cycleDuration, ease: 'none' }
    );

    return seamlessLoop;
  };

  const cards = gsap.utils.toArray('.cards.buy li');
  const cardstp = gsap.utils.toArray('.cards.tp li');
  const cardssl = gsap.utils.toArray('.cards.sl li');
  const cardsbp = gsap.utils.toArray('.cards.bp li');
  const cardgas = gsap.utils.toArray('.cards.gas li');
  const cardslip = gsap.utils.toArray('.cards.slip li');

  const seamlessLoop = buildSeamlessLoop(cards, spacingSCR, animateFunc);
  const seamlessLooptp = buildSeamlessLoop(cardstp, spacingSCR, animateFunc);
  const seamlessLoopsl = buildSeamlessLoop(cardssl, spacingSCR, animateFunc);
  const seamlessLoopbp = buildSeamlessLoop(cardsbp, spacingSCR, animateFunc);
  const seamlessLoopgas = buildSeamlessLoop(cardgas, spacingSCR, animateFunc);
  const seamlessLoopslip = buildSeamlessLoop(cardslip, spacingSCR, animateFunc);

  const playhead = { offset: 0 },
    wrapTime = gsap.utils.wrap(0, seamlessLoop.duration()),
    wrapTimetp = gsap.utils.wrap(0, seamlessLooptp.duration()),
    wrapTimesl = gsap.utils.wrap(0, seamlessLoopsl.duration()),
    wrapTimebp = gsap.utils.wrap(0, seamlessLoopbp.duration()),
    wrapTimegas = gsap.utils.wrap(0, seamlessLoopgas.duration()),
    wrapTimeslip = gsap.utils.wrap(0, seamlessLoopslip.duration()),
    scrub = gsap.to(playhead, {
      offset: 0,
      onUpdate() {
        seamlessLoop.time(wrapTime(playhead.offset));
      },
      duration: 0.5,
      ease: 'power3',
      paused: true,
    }),
    scrubtp = gsap.to(playhead, {
      offset: 0,
      onUpdate() {
        seamlessLooptp.time(wrapTimetp(playhead.offset));
      },
      duration: 0.5,
      ease: 'power3',
      paused: true,
    }),
    scrubsl = gsap.to(playhead, {
      offset: 0,
      onUpdate() {
        seamlessLoopsl.time(wrapTimesl(playhead.offset));
      },
      duration: 0.5,
      ease: 'power3',
      paused: true,
    }),
    scrubbp = gsap.to(playhead, {
      offset: 0,
      onUpdate() {
        seamlessLoopbp.time(wrapTimebp(playhead.offset));
      },
      duration: 0.5,
      ease: 'power3',
      paused: true,
    }),
    scrubgas = gsap.to(playhead, {
      offset: 0,
      onUpdate() {
        seamlessLoopgas.time(wrapTimegas(playhead.offset));
      },
      duration: 0.5,
      ease: 'power3',
      paused: true,
    }),
    scrubslip = gsap.to(playhead, {
      offset: 0,
      onUpdate() {
        seamlessLoopslip.time(wrapTimeslip(playhead.offset));
      },
      duration: 0.5,
      ease: 'power3',
      paused: true,
    });

  const draggable = Draggable.create('.drag-proxy.buy', {
    type: 'x',
    trigger: '.cards.buy',
    onPress() {
      this.startOffset = scrub.vars.offset;
    },
    onDrag() {
      scrub.vars.offset = this.startOffset + (this.startX - this.x) * 0.001;
      scrub.invalidate().restart();
      vm.component.fillByScroll(GetValue(this.x, 0));
    },
    onDragEnd() {},
  });

  const draggabletp = Draggable.create('.drag-proxy.tp', {
    type: 'x',
    trigger: '.cards.tp',
    onPress() {
      this.startOffset = scrubtp.vars.offset;
    },
    onDrag() {
      scrubtp.vars.offset = this.startOffset + (this.startX - this.x) * 0.001;
      scrubtp.invalidate().restart();
      vm.component.setLimits(GetValue(this.x, 1), true);
    },
    onDragEnd() {},
  });

  const draggablesl = Draggable.create('.drag-proxy.sl', {
    type: 'x',
    trigger: '.cards.sl',
    onPress() {
      this.startOffset = scrubsl.vars.offset;
    },
    onDrag() {
      scrubsl.vars.offset = this.startOffset + (this.startX - this.x) * 0.001;
      scrubsl.invalidate().restart();
      vm.component.setLimits(GetValue(this.x, 2), false);
    },
    onDragEnd() {},
  });

  const draggablebp = Draggable.create('.drag-proxy.bp', {
    type: 'x',
    trigger: '.cards.bp',
    onPress() {
      this.startOffset = scrubbp.vars.offset;
    },
    onDrag() {
      scrubbp.vars.offset = this.startOffset + (this.startX - this.x) * 0.001;
      scrubbp.invalidate().restart();
      vm.component.setBuyingPrice(GetValue(this.x, 3));
    },
    onDragEnd() {},
  });

  const draggablegas = Draggable.create('.drag-proxy.gas', {
    type: 'x',
    trigger: '.cards.gas',
    onPress() {
      this.startOffset = scrubgas.vars.offset;
    },
    onDrag() {
      scrubgas.vars.offset = this.startOffset + (this.startX - this.x) * 0.001;
      scrubgas.invalidate().restart();
      vm.component.setRangeSettings(GetValue(this.x, 4), 0);
    },
    onDragEnd() {},
  });

  const draggableslip = Draggable.create('.drag-proxy.slip', {
    type: 'x',
    trigger: '.cards.slip',
    onPress() {
      this.startOffset = scrubslip.vars.offset;
    },
    onDrag() {
      scrubslip.vars.offset = this.startOffset + (this.startX - this.x) * 0.001;
      scrubslip.invalidate().restart();
      vm.component.setRangeSettings(GetValue(this.x, 5), 1);
    },
    onDragEnd() {},
  });

  const GetValue = (val, i) => {
    let currentValues = vm.component.getCurrentValues();
    globalThis.increments[i] = val - globalThis.lastValues[i];
    globalThis.lastValues[i] = val;

    currentValues[i] += globalThis.increments[i];
    if (i === 0 || i === 2) currentValues[i] = currentValues[i] >= 500 ? 500 : currentValues[i] <= 0 ? 0 : currentValues[i];
    else if (i === 5) currentValues[i] = currentValues[i] >= 500 ? 500 : currentValues[i] <= 1 ? 1 : currentValues[i];
    else if (i === 4) currentValues[i] = currentValues[i] >= 5000 ? 5000 : currentValues[i] <= 0 ? 0 : currentValues[i];
    else currentValues[i] = currentValues[i] <= 0 ? 0 : currentValues[i];

    vm.component.setCurrentValues(currentValues);

    let res = Math.round(currentValues[i] / 5);

    if (i === 0 || i === 2) res = res >= 100 ? 100 : res <= 0 ? 0 : res;
    else if (i === 4) res = res >= 1000 ? 1000 : res <= 0 ? 0 : res;
    else if (i === 5) res = res >= 100 ? 100 : res <= 1 ? 1 : res;
    else if (i === 3) res = globalThis.increments[i];
    else res = res <= 0 ? 0 : res;

    return res;
  };

  return {
    init,
  };
};

export default {
  init,
};
