<template>
 <div class="paginator" :class="classPlatform" >
    <button-transparent :class="text[0] != ''?'':'hidden'"
                        text="<" :state="states.ButtonTransparentStates.tertiary"
                        @click="$emit('paginatorHandler',
                        handlerArgs[0])" />
    <button-transparent :text="text[1]" :state="states.ButtonTransparentStates.tertiary" />
    <button-transparent :class="text[2] != ''?'':'hidden'"
                        text=">" :state="states.ButtonTransparentStates.tertiary"
                        @click="$emit('paginatorHandler',
                        handlerArgs[1])" />
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')
import ButtonTransparent from './ButtonTransparent.vue'

export default {
  name: 'PaginatorComp',
  components: {
    ButtonTransparent
  },
  inject: injects.getInjects(),
  props: ["text", "active", "handlerArgs"],
  emits: ['paginatorHandler'],
  data() {
    return {
    states: states
    }
  }
}
</script>
<style scoped>
.paginator {
  align-items: flex-start;
  display: flex;
  position: relative;
}

.paginator.mobile {
  justify-content: center;
  width: fit-content;
}
.paginator.desktop {
  justify-content: center;
  width: fit-content;
}
</style>
