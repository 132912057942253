<template>
 <div :active="active" class="popupcontentprofile" :class="[classPlatform]">
    <img class="helpimg" src="/img/search.gif" />
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import ButtonTransparent from '../basic/ButtonTransparent.vue';
import ButtonFull from '../basic/ButtonFull.vue';
import TextComp from '../basic/TextComp.vue';
import IconComp from '../basic/IconComp.vue';
export default {
  name: 'PopupHelp',
  components: {
    ButtonTransparent,ButtonFull,TextComp, IconComp
  },
  inject: injects.getInjects(),
  props: ["active","reset"],
  data () {
    return {
      states:states,
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.confirm-buttons {
  align-self: stretch;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  position: relative;
}
.helpimg {
  width: 100%;
}
.confirm-buttons.desktop{
  margin-left: 21.22%;
  margin-right: 21.22%;
}
.confirm-buttons.mobile{
  margin-left: 14.54%;
  margin-right: 14.54%;
}
.popupcontentprofile {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}
.profile-diamond {
  position: absolute;
  display: flex;
  flex-direction: row;
}
.profile-diamond.desktop {
  top:-2.5em;
  right:-1em;
}
.profile-diamond.mobile {
  top:-2em;
  right:-1em;
}
.popupcontentprofile.desktop {
  gap: 26px;
}
.popupcontentprofile.mobile {
  gap: 14px;
}
.popupcontentprofile .content{
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
}
.popupcontentprofile .content.desktop{
  gap: 16px;
}
.popupcontentprofile .content.mobile{
  gap: 8px;
}
.popupcontentprofile .content .data{
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.popupcontentprofile .content .data .buttons{
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.popupcontentprofile .content .data .buttons.mobile{
  gap: 14px;
}
.popupcontentprofile .content .data .buttons.desktop{
  gap: 25px;
}
</style>
