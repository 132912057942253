<template>
 <div :connected="connected" :state="state" class="mainview" :class="[classPlatform, classTablet]">
  <main-view-header-button-bar />
  <div v-if="!isChart" class="content-holder" :class="[classPlatform]">
    <main-view-content-header v-show="isWallets"
      :state="states.MainViewStatus.Content.Header.Wallets.name"
      :status="computedWalletsStatus" />
    <main-view-content-header v-if="isOrders"
      :state="states.MainViewStatus.Content.Header.Orders.name"
      :status="states.MainViewStatus.Content.Header.Orders.Default" />
    <main-view-content-header v-if="isBase"
      :state="states.MainViewStatus.View.Base"/>
    <snipe-view-content-header v-if="isSnipe" />
    <div class="content-view" :class="[classPlatform]">
      <div class="content-area" :class="[classPlatform]">
        <div class="scrollable-holder" :class="[classPlatform]">
          <div v-if="isWallets&&!this.secretWallets" class="content" :class="[classPlatform]">
            <text-comp class="left" v-if="this.showEmpty()" text="WALLET LIST IS EMPTY."
            :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
            <main-view-wallet-row v-for="(item, index) in computedWallets" :key="item.address.toString()+index"
              v-show="showWalletRow(index)"
              :state="!item.deleted?states.MainViewStatus.Content.WalletRow.View:states.MainViewStatus.Content.WalletRow.Deleted"
              :item="item"
              :index="index" />
          </div>
          <div v-if="isWallets&&this.secretWallets" class="content" :class="[classPlatform]">
            <main-view-wallet-row class="pointer prevent-select" v-for="(item, index) in computedSecretWallets"
              :active="secretWalletSelected === item.privateKey?true:null"
              :key="index.toString()+item.address.toString()+item.privateKey.toString()+(item.balance?item.balance.toString():'0')"
              :state="states.MainViewStatus.Content.WalletRow.SelectForImport"
              :item="item"
              :index="index"
              @click="selectSecretWallet(item.privateKey)"/>
          </div>
          <div v-if="isOrders&&computedShowOrdersHistory" class="content" :class="[classPlatform]">
            <order-history v-for="(item,index) in computedCutOrders"
              :key="item.settings.token.symbol.toString()+index"
              :state="states.OrderHistory.Type.manual"
              :item="item"
              :index="index" />
          </div>
          <div v-if="isOrders&&!computedShowOrdersHistory&&!computedShowMarketOrders" class="content" :class="[classPlatform]">
            <order-running v-for="item in autoTrades"
              v-show="!item.deleted&&!item.trade.settings.trade.launchBuy"
              :key="item.pid.toString()+atRerenderer"
              :state="item.trade.settings.trade.sell?states.OrderRunning.LimitType.sell:states.OrderRunning.LimitType.buy"
              :item="item"
              :index="index" />
          </div>
          <div v-if="isSnipe" class="content" :class="[classPlatform]">
            <text-comp v-if="false&&showActiveSnipes" class="left" text="Your running launch buys."
            :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp v-if="false&&!showActiveSnipes" class="left" text="Your launch buys history."
            :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
            <order-running v-for="item in autoTrades"
              v-show="item.trade.settings.trade.launchBuy&&!item.status.includes('error')&&item.status!='success'&&item.status!='canceled'&&showActiveSnipes"
              :key="item.pid.toString()+'launchB'+atRerenderer"
              :state="item.trade.settings.trade.sell?states.OrderRunning.LimitType.sell:states.OrderRunning.LimitType.buy"
              :item="item"
              :index="index" />
              <order-running v-for="item in autoTrades"
              v-show="item.trade.settings.trade.launchBuy&&item.status != 'running'&&!showActiveSnipes"
              :key="item.pid.toString()+'launchB'+atRerenderer"
              :state="item.trade.settings.trade.sell?states.OrderRunning.LimitType.sell:states.OrderRunning.LimitType.buy"
              :item="item"
              :index="index" />
          </div>
          <div v-if="isBase" class="content" :class="[classPlatform]">
            <withdraw-entry v-for="(item, index) in withdrawalsArray" :key="item.created.toString()+index"
            :state="states.MainViewStatus.Content.WalletRow.WithdrawBase"
            :item="item"
            :index="index" />
          </div>
        </div>
        <div class="paginator-row" v-if="isWallets&&this.secretWallets" :class="classPlatform">
          <paginator-comp v-if="isWallets&&this.secretWallets" :text="[(canChangeSWPage(false)?'&lt':''),
                                                                  `${this.swPage + 1}`,
                                                                  (canChangeSWPage(true)?'&gt':'')]"
          @paginatorHandler="changeSWPage"
          :handlerArgs="[false,true]" />
          <div class="paginator-buttons" :class="classPlatform">
            <button-transparent text="cancel" :state="states.ButtonTransparentStates.tertiary" @click="clearSecret()"/>
            <button-transparent text="import" :state="states.ButtonTransparentStates.tertiary" @click="importSecretWallet()"/>
          </div>
        </div>
        <div class="paginator-row" v-if="isOrders&&computedShowOrdersHistory&&ordersArray&&ordersArray.length>0" :class="classPlatform" >
          <paginator-comp :text="[(canChangeHPage(false)?'&lt':''),
                                                                  `${this.hPage + 1}`,
                                                                  (canChangeHPage(true)?'&gt':'')]"
          @paginatorHandler="changeHPage"
          :handlerArgs="[false,true]" />
        </div>
      </div>
    </div>
    <div v-if="!isConnected" class="not-connected" :class="[classPlatform]">
      <text-comp class="center" text="you are not connected." :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
      <br />
      <text-comp class="center" text="connect first if you want to get access to this section." :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
    </div>
  </div>
  <div v-show="isChart" class="chart-holder" :class="[classPlatform]">
    <div v-if="this.currentToken&&this.currentToken.pair" id="dexscreener-embed" :class="[classPlatform]" >
      <iframe frameborder="0" v-bind:src="'https://dexscreener.com/'+(isBNB(this.currentToken.chainID)?'bsc':this.currentToken.chainID==8453?'base':'ethereum')+'/'+(useDirectPairOnStable()?this.currentToken.directPair:this.currentToken.pair)+'?embed=1&theme=dark&trades=0&info=0'" :key="reRenderChart">
      </iframe>
    </div>
    <div v-if="!currentToken" class="not-connected" :class="[classPlatform]">
      <text-comp class="center" text="Use the auto-search field above to start trading." :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
      <br />
      <button-transparent class="center" text="Need help?" :active="true" :state="states.ButtonTransparentStates.tertiary" @click="showHelpPopup()"/>
    </div>
  </div>
 </div>
</template>
<script>
/* eslint-disable */

const injects = require('../../modules/injects')
const states = require('../../modules/states')

import MainViewHeaderButtonBar from '../bricks/MainViewHeaderButtonBar.vue'
import MainViewContentHeader from '../bricks/MainViewContentHeader.vue'
import SnipeViewContentHeader from '../bricks/SnipeViewContentHeader.vue'
import TextComp from '../basic/TextComp.vue'
import PaginatorComp from '../basic/PaginatorComp.vue'
import MainViewWalletRow from '../bricks/MainViewWalletRow.vue'
import OrderHistory from '../basic/OrderHistory.vue'
import ButtonTransparent from '../basic/ButtonTransparent.vue'
import OrderRunning from '../basic/OrderRunning.vue'
import OrderMarket from '../basic/OrderMarket.vue'
import WithdrawRow from '../bricks/WithdrawRow.vue'
import WithdrawEntry from '../bricks/WithdrawEntry.vue'


export default {
  name: 'MainView',
  components: {
    MainViewHeaderButtonBar,MainViewContentHeader,
    TextComp,
    PaginatorComp,
    MainViewWalletRow,
    OrderHistory,
    ButtonTransparent,
    OrderRunning,
    OrderMarket,
    WithdrawRow,
    WithdrawEntry,
    SnipeViewContentHeader
  },
  inject: injects.getInjects(),
  props: ["state","connected"],
  data () {
    return {
      states: states
    }
  },
  computed: {
    computedWalletsStatus() {
      if(this.computedShowImportWallet) return states.MainViewStatus.Content.Header.Wallets.ImportShowField
      if(this.secretWallets) return states.MainViewStatus.Content.Header.Wallets.ImportSelect;
      if(!this.computedShowTrashClass) return states.MainViewStatus.Content.Header.Wallets.Deleted;
      else return states.MainViewStatus.Content.Header.Wallets.Default;
    },
    isChart() {
      return this.state == states.MainViewStatus.View.Chart;
    },
    isWallets() {
      return this.state == states.MainViewStatus.View.Wallets;
    },
    isOrders() {
      return this.state == states.MainViewStatus.View.Orders;
    },
    isBase() {
      return this.state == states.MainViewStatus.View.Base;
    },
    isSnipe() {
      return this.state == states.MainViewStatus.View.Snipe;
    },
    isConnected() {
      return this.connected != null;
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.mainview {
  align-items: flex-start;
  border: 2px solid;
  border-color: var(--sepia-skin);
  border-radius: 4px 4px 0px 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  flex: 1;
}
.mainview.desktop {
  min-height: 280px;
}
.mainview.mobile {
  min-height: 192px;
  border-radius: 0px 0px 0px 0px;
}
.mainview.tablet {
  min-height: 192px;
  border-radius: 4px 4px 0px 0px;
}
.mainview .chart-holder {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  align-self: stretch;
  overflow: hidden;
}
.mainview .chart-holder.desktop {
}
.mainview .chart-holder.mobile {
}
.mainview .chart-holder dexscreener-embed {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  align-self: stretch;
  overflow: hidden;
}
.mainview .content-holder {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  align-self: stretch;
  overflow: hidden;
}
.mainview .content-holder.desktop {
  gap: 15px;
  padding: 14px 14px;
}
.mainview .content-holder.mobile {
  gap: 10px;
  padding: 12px 12px;
}
.mainview .content-holder .content-view {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  gap: 10px;
  padding: 0px 0px 0px 0px;
}
.mainview .content-holder .content-view .content-area {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.mainview .content-holder .content-view .content-area .paginator-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}
.mainview .content-holder .content-view .content-area .paginator-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  gap:17px
}
.mainview .content-holder .content-view .content-area.desktop {
  gap: 15px;
}
.mainview .content-holder .content-view .content-area.mobile {
  gap: 8px;
}
.mainview .content-holder .content-view .content-area .scrollable-holder {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
}
.mainview .content-holder .content-view .content-area .scrollable-holder.desktop {
  gap: 15px;
}
.mainview .content-holder .content-view .content-area .scrollable-holder.mobile {
  gap: 8px;
}
.mainview .content-holder .content-view .content-area .scrollable-holder .content {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  width: 100%;
}
.mainview .content-holder .content-view .content-area .scrollable-holder .content.desktop {
  padding-right: 15px;
}
.mainview .content-holder .content-view .content-area .scrollable-holder .content.mobile {
  padding-right: 8px;
}
.not-connected {
  justify-content: center;
  align-content: center;
  background-color: var(--bon-jour);
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.not-connected .center {
  align-self: center;
}
</style>
