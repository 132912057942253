<template>
 <div :connected="connected" :state="state" class="mainscreen" :class="[classPlatformTabletOverride, classTablet]">
    <header-comp />
    <div class="columns" :class="[classPlatformTabletOverride]">
      <div class="console-holder" :class="[classPlatformTabletOverride]">
        <console-view />
      </div>
      <div class="right-column" :class="[classPlatformTabletOverride]">
        <main-view :connected="isConnected" :state="getCurrentViewState"/>
        <buy-panel-view :connected="isConnected" />
      </div>
    </div>
  <popup-view v-if="this.showLogSection('popup')||this.settingsActive || this.profileActive || this.helpActive" :item="walletForDeletion != null?computedWallets[walletForDeletion]:null" :warning="this.showLogSection('popup')?true:null"/>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import HeaderComp from '../bricks/HeaderComp.vue'
import BuyPanelView from '../windows/BuyPanelView.vue'
import ConsoleView from '../windows/ConsoleView.vue'
import PopupView from '../windows/PopupView.vue'
import MainView from '../windows/MainView.vue'
export default {
  name: 'MainScreen',
  components: {
    HeaderComp,BuyPanelView,
    ConsoleView,PopupView,MainView
  },
  inject: injects.getInjects(),
  props: ["state","connected"],
  data () {
    return {
      states: states
    }
  },
  computed: {
    getCurrentViewState() {
      if(this.showLogSection('chart')) return states.MainViewStatus.View.Chart;
      if(this.showLogSection('wallets')) return states.MainViewStatus.View.Wallets;
      if(this.showLogSection('orders')) return states.MainViewStatus.View.Orders;
      if(this.showLogSection('base')) return states.MainViewStatus.View.Base;
      if(this.showLogSection('snipe')) return states.MainViewStatus.View.Snipe;
      else return states.MainViewStatus.View.Chart;
    },
    isConnected() {
      return this.connected != null?this.connected:null;
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.mainscreen {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}
.mainscreen.desktop {
  padding: 24px 10px 10px 10px;
  gap: 10px;
  max-width: 1200px;
  max-height: 1000px;
  overflow-y: auto;
}
.mainscreen.desktop.tablet {
  max-width: 920px;
}
.mainscreen.mobile {
  gap: 5px;
  padding: 24px 5px 5px 5px;
}
.mainscreen .columns {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 100%;
}
.mainscreen .columns.desktop {
  flex-direction: row;
  gap: 10px;
  height: calc(100% - 40px - 10px);
}
.mainscreen .columns.mobile {
  flex-direction: column;
  height: calc(100% - 60px);
}
.mainscreen .columns .console-holder {
  display: flex;
  width: 100%;
}
.mainscreen .columns .console-holder.desktop {
  flex: 1;
  height: 100%;
  width: calc(42% - 5px);
}
.mainscreen .columns .console-holder.mobile {
  flex: 0 1 116px;
  min-height: 116px;
  max-height: 116px;
}
.mainscreen .columns .right-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
.mainscreen .columns .right-column.desktop {
  width: calc(58% - 5px);
}
.mainscreen .columns .right-column.mobile {
  height: calc(100% - 116px - 5px);
}
</style>
