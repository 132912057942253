<template>
 <div :active="active" :state="state" :context="context" class="mainviewwalletrow" :class="[classPlatform, classUse]">
  <text-comp class="pointer"
  v-if="!renameActive(index)"
  :text="!item.tag?item.address:item.tag"
  :wrap="states.TextStatesStyles.BaseText.Wrap.truncated"
  :color="textColor"
  :key="renameKey"
  @click="!isSelectForImport?copyAddress(item.address):''"/>
  <input-comp class="renameInput" :class="[classPlatform,inputClass]"
  v-if="renameActive(index)"
  type="text"
  :name="'renameValue'"
  :placeh="'ENTER NEW WALLET TAG'"
  :value="renameValue"
  :key="renameKey"
  @inputHandler="inputFieldHandler"
  v-click-out-side="hideThis" />
  <div class="buttons">
    <div class="balance">
      <text-comp :key="reRender" :text="item.balance?item.balance:'0'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="textColor" />
      <text-comp :key="reRender" :text="computedWETH" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="textColor" />
    </div>
    <button-transparent v-show="showUse"
    :active="item.used?true:null"
    :text="item.used?'use:on ':'use:off'" :state="states.ButtonTransparentStates.tertiary"
    @click="updateWalletUse(index)"
    :key="reRenderUse" />
    <wallets-context-menu
    :item="item"
    :index="index"
    v-if="isShowOptionsPopUp(item.address)"
    class="context-popup"
    :class="classPlatform"
    :deleted="item.deleted?true:null"/>
    <icon-comp v-show="showContext" :state="states.IconTypes.context" @click="showOptionsPopUp(item.address)"/>
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import IconComp from '../basic/IconComp.vue';
import TextComp from '../basic/TextComp.vue'
import ButtonTransparent from '../basic/ButtonTransparent.vue';
import InputComp from '../basic/InputComp.vue';
import WalletsContextMenu from '../bricks/WalletsContextMenu.vue';

export default {
  name: 'MainViewWalletRow',
  directives: {
    clickOutSide,
  },
  components: {
    IconComp,ButtonTransparent,TextComp,
    InputComp,
    WalletsContextMenu,
  },
  inject: injects.getInjects(),
  props: ["active","state", "context", "item", "index"],
  data () {
    return {
      states: states,
      use: false,
      clicks: 0
    }
  },
  computed: { 
    inputClass() {
      return this.classPlatform.desktop?states.TextStatesStyles.BaseText.desktop.default:states.TextStatesStyles.BaseText.mobile.default;
    },
    textColor() {
      return this.active!=null?states.TextStatesStyles.BaseText.Color.dark:states.TextStatesStyles.BaseText.Color.default
    },
    classUse() {
      return {
        pointer: this.use,
        "prevent-select": this.use
      }
    },
    isView() {
      return this.state == states.MainViewStatus.Content.WalletRow.View;
    },
    isSelectForImport() {
      return this.state == states.MainViewStatus.Content.WalletRow.SelectForImport;
    },
    isDeleted() {
      return this.state == states.MainViewStatus.Content.WalletRow.Deleted;
    },
    showContext() {
      return !this.isSelectForImport;
    },
    showUse() {
      return this.isView;
    }
  },
  methods: {
    hideThis(e) {
      this.clicks++;
      if(this.clicks > 1) {
        this.clicks = 0;
        this.hideRename(e);
      }
    }
  }
}
</script>
<style scoped>
.renameInput {
  border: 1px solid var(--sepia-skin);
  border-radius: 2px;
}
.renameInput.desktop {
  padding: 0px 6px 0px;
}
.renameInput.mobile {
  padding: 0px 3px 0px;
}
.mainviewwalletrow {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.mainviewwalletrow.desktop {
  height: 27px;
  gap: 12px;
}
.mainviewwalletrow.mobile {
  height: 20px;
}
.mainviewwalletrow .buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.mainviewwalletrow .buttons.desktop {
  gap: 8px;
  height: 21px;
}
.mainviewwalletrow .buttons.mobile {
  gap: 8px;
  height: 15px;
  margin-top: -1px;
}
.mainviewwalletrow .buttons .balance {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
}
.mainviewwalletrow .buttons .context-popup {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}
</style>
