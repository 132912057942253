<template>
  <div :active="active" :color="color" :state="state" class="prevent-select pointer buttontransparent" :class="[classStates,classPlatform,classProps,iconClass]" :text="text"
  @mouseover="hover = true" @mouseleave="hover = false" >
    <icon-comp v-if="checkFavs"
      :class="[classStates,classPlatform,'icon', order]"
      :state="states.IconTypes.Chains[getChainName]"
      :active="active || this.hover"
      flexible/>
    <div class="button valign-text-middle" :class="[classStates,classPlatform,classProps,classTextState, classTablet]">{{text?text:"button"}}</div>
  </div>
</template>
<script>
/* eslint-disable */
import IconComp from './IconComp.vue'
const injects = require('../../modules/injects')
const states = require('../../modules/states')
const chains = require('../../modules/chains')
export default {
  name: 'ButtonTransparent',
  components: {
    IconComp
  },
  inject: injects.getInjects(),
  props: ["text", "active", "state", "color", "chainIcon"],
  data() {
    return {
        states: states,
        hover: false
    }
  },
  computed: {
    checkFavs() {
      return this.state == states.ButtonTransparentStates.favorites || this.chainIcon;
    },
    getChainName() {
      return chains.getChain(parseInt(this.chainIcon)).symbol;
    },
    classTextState() {
      return states.TextStatesStyles.ButtonTransparent.getClass(this.classPlatform,
              this.state,this.hover,this.active != null?true:false, this.color);
    },
    classProps() {
      return {
        active: this.active != null
      }
    },
    classStates() {
      return  (this.classPlatform.desktop &&
              this.state == states.ButtonTransparentStates.connect)? 
              states.ButtonTransparentStates.primary
              :states.getButtonTransparentStateClassName(this.state);
    }, 
    order() {
      return this.state == states.ButtonTransparentStates.favorites ? 'order0':'order2';
    },
    iconClass() {
      return this.checkFavs?'containsicon':'';
    }
  }
}
</script>
<style scoped>
.buttontransparent.primary.mobile {
  background-color: transparent;
  border: 0.5px solid;
  border-radius: 4px;
  box-shadow: 1px 1px 0px var(--sepia-skin-2);
  height: 24px;
  padding: 0px 10px 0px;
  align-items: center;
  border-color: var(--sepia-skin);
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: fit-content;
}
.buttontransparent.primary.mobile:hover {
  box-shadow: 0.5px 0.5px 0px var(--irish-coffee);
}
.buttontransparent.secondary.mobile {
  border: 0.5px solid;
  height: 20px;
  align-items: center;
  border-color: var(--sepia-skin);
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 0px 6px 3px;
  position: relative;
  width: fit-content;
}
.buttontransparent.primary.desktop {
  align-items: center;
  background-color: transparent;
  border: 1px solid;
  border-color: var(--sepia-skin);
  border-radius: 6px;
  box-shadow: 1px 1px 0px var(--sepia-skin-2);
  display: flex;
  gap: 10px;
  height: 32px;
  justify-content: center;
  padding: 0px 10px;
  position: relative;
  width: fit-content;
}
.buttontransparent.primary.desktop:hover {
  box-shadow: 0.5px 0.5px 0px var(--irish-coffee);
}
.buttontransparent.secondary.desktop {
  align-items: center;
  border: 2px solid;
  border-color: var(--sepia-skin);
  display: flex;
  gap: 10px;
  height: 24px;
  justify-content: center;
  padding: 0px 6px 3px;
  position: relative;
  width: fit-content;
}
.buttontransparent.tertiary.desktop {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 0px 6px 0px;
  position: relative;
  width: fit-content;
}
.buttontransparent.tertiary.desktop.containsicon {
  height: var(--font-size-m);
  gap: 3px;
}
.buttontransparent.tertiary.mobile {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: fit-content;
  padding: 0px 3px 0px;
}
.buttontransparent.tertiary.mobile.containsicon {
  height: 12px;
  gap: 2px;
}
.buttontransparent.favorites.desktop {
  align-items: center;
  background-color: var(--satin-linen);
  border: 0.5px solid;
  border-color: var(--sepia-skin);
  border-radius: 4px;
  box-shadow: 1px 1px 0px var(--sepia-skin-2);
  display: flex;
  gap: 3px;
  height: 24px;
  justify-content: center;
  padding: 3px 6px 3px 6px;
  position: relative;
  width: fit-content;
}
 .buttontransparent.favorites.mobile {
  align-items: center;
  background-color: var(--satin-linen);
  border: 0.5px solid;
  border-color: var(--sepia-skin);
  border-radius: 4px;
  box-shadow: 1px 1px 0px var(--sepia-skin-2);
  display: flex;
  gap: 2px;
  height: 16px;
  justify-content: center;
  padding: 2px 3px 2px 3px;
  position: relative;
  width: fit-content;
}
.buttontransparent.connect.mobile {
  align-items: center;
  background-color: transparent;
  border: 1px solid;
  border-color: var(--sepia-skin);
  border-radius: 4px;
  box-shadow: 1px 1px 0px var(--sepia-skin-2);
  display: flex;
  gap: 10px;
  height: 24px;
  justify-content: center;
  padding: 0px 10px 0px;
  position: relative;
  width: fit-content;
}
.buttontransparent.connect.mobile:hover {
  box-shadow: 0.5px 0.5px 0px var(--irish-coffee);
}
.button {
  letter-spacing: 0;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  display: flex;
  order: 1;
}
.buttontransparent .icon {
  height: 100%;
}
.icon.order0 {
  order: 0;
}
.icon.order2 {
  order: 2;
}
.button.secondary {
  text-transform: uppercase;
}
.button.tertiary {
  text-transform: uppercase;
}
.button.favorites {
  font-style: normal;
}
</style>