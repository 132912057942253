<template>
 <div :active="active" class="mainviewheaderbuttonbar" :class="[classPlatform]">
  <window-bar absolute />
  <div class="buttons" :class="[classPlatform]">
    <button-full text="CHART"
    :active="showLogSection('chart')?true:null"
    @click="switchLogSection('chart')" />
    <button-full text="WALLETS"
    :active="showLogSection('wallets')?true:null"
    @click="switchLogSection('wallets')" />
    <button-full text="ORDERS"
    :active="showLogSection('orders')?true:null"
    @click="switchLogSection('orders')" />
    <button-full text="ASS"
    :active="showLogSection('snipe')?true:null"
    @click="switchLogSection('snipe')" />
    <button-full text="BRIDGE0"
    :active="showLogSection('base')?true:null"
    @click="switchLogSection('base')" />
  </div>
 </div>
</template>
<script>
/* eslint-disable */
/*
<button-full text="ASS"
    :active="showLogSection('snipe')?true:null"
    @click="switchLogSection('snipe')" />
    */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import ButtonFull from '../basic/ButtonFull.vue';
import WindowBar from '../basic/WindowBar.vue';
export default {
  name: 'MainViewHeaderButtonBar',
  components: {
    ButtonFull,WindowBar
  },
  inject: injects.getInjects(),
  props: ["active"],
  data () {
    return {
      states:states    
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.mainviewheaderbuttonbar {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.mainviewheaderbuttonbar.desktop {
  height: 32px;
}
.mainviewheaderbuttonbar.mobile {
  height: 20px;
}
.mainviewheaderbuttonbar .buttons {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  position: relative;
}
.mainviewheaderbuttonbar .buttons.desktop {
  gap: 2px;
}
.mainviewheaderbuttonbar .buttons.mobile {
  gap: 1px;
}
</style>
