<template>
 <div :active="active" class="headercomp" :class="[classPlatformTabletOverride,classTablet]">
  <div class="buttons" :class="[classPlatformTabletOverride]">
      <icon-comp
      class="icon"
      :href="'https://t.me/defcafesociety'"
      :state="states.IconTypes.telegram" :class="this.classOrder(0)"/>
      <icon-comp
      class="icon"
      :href="'https://docs.def.cafe/'"
      :state="states.IconTypes.questionmark" :class="this.classOrder(1)"/>
      <icon-comp
      class="icon"
      :state="states.IconTypes.settings"
      :class="this.classOrder(3)"
      :active="this.settingsActive?true:null" @click="showSettingsPopup()"/>
      <icon-comp
      class="icon"
      :state="states.IconTypes.profile"
      :class="this.classOrder(3)"
      @click="showProfilePopup()"/>
      <div class="connect-button" :class="[classPlatformTabletOverride,classTablet,classPlatformTabletOverride.desktop?this.classOrder(4):this.classOrder(1)]">
        <button-transparent id="connectButton"
        :text="this.providerConnect?'DISCONNECT':'CONNECT'"
        :state="states.ButtonTransparentStates.connect" :class="[classPlatformTabletOverride]"
        @click="LoginManagerConnect()"/>
      </div>
  </div>
  <div class="searchbar-holder" :class="[classPlatformTabletOverride]">
    <search-bar />
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import ButtonTransparent from '../basic/ButtonTransparent.vue';
import IconComp from '../basic/IconComp.vue';
import SearchBar from '../basic/SearchBar.vue';
export default {
  name: 'HeaderComp',
  components: {
    ButtonTransparent,IconComp,SearchBar
  },
  inject: injects.getInjects(),
  props: ["active"],
  data () {
    return {
      states:states    
    }
  },
  methods: {
    classOrder(val) {
      return `order${val}`;
    }
  }
}
</script>
<style scoped>
.headercomp {
  position: relative;
  display: flex;
}
.headercomp .buttons .icon{
  flex: 0 0 auto;
}
.headercomp.desktop {
  height: 40px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
  gap: 0px;
  flex: 1;
  min-height: 40px;
  max-height: 40px;
  width: 100%;
}
.headercomp.desktop.tablet {
  flex: unset;
  height: unset;
  max-height: unset;
  min-height: unset;
}
.headercomp.mobile {
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  flex: 0 1 60px;
  min-height: 60px;
  max-height: 60px;
}
.headercomp .searchbar-holder {
  display: flex;
  width: 100%;
  padding-right: 1px;
}
.headercomp .searchbar-holder.desktop {
  width: calc(58% - 5px);
  align-items: center;
}
.headercomp .buttons {
  position: relative;
  display: flex;
  align-items: center;
}
.headercomp .buttons .connect-button.mobile{
  position: relative;
  align-items: center;
  display: flex;
  align-self: center;
  justify-content: center;
  flex: 1;
}
.headercomp .buttons.desktop {
  align-items: center;
  gap: 17px;
  width: calc(42% - 5px);
  padding-left: 21px;
}
.headercomp .buttons.mobile {
  align-self: stretch;
  justify-content: space-between;
  padding: 0px 12px;
  gap: 6px;
}
.headercomp .buttons .order0 {
  order: 0
}
.headercomp .buttons .order1 {
  order: 1
}
.headercomp .buttons .order2 {
  order: 2
}
.headercomp .buttons .order3 {
  order: 3
}
.headercomp .buttons .order4 {
  order: 4
}
</style>
