<template>
 <div :connected="connected" :state="state" class="buypanelview" :class="[classPlatform]">
  <buy-panel-header />
  <buy-panel-content :connected="this.connected"/>
 </div>
</template>
<script>
import BuyPanelContent from '../bricks/BuyPanelContent.vue'
import BuyPanelHeader from '../bricks/BuyPanelHeader.vue'
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')
export default {
  name: 'BuyPanelView',
  components: {
    BuyPanelHeader,
    BuyPanelContent
  },
  inject: injects.getInjects(),
  props: ["state","connected"],
  data () {
    return {
      states: states
    }
  },
  computed: {
    isConnected() {
      return this.connected != null;
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.buypanelview {
  align-items: flex-start;
  border: 2px solid;
  border-color: var(--sepia-skin);
  border-radius: 0px 0px 4px 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.buypanelview.desktop {
  flex: 0 1 350px;
  min-height: 350px;
  max-height: 350px;
}
.buypanelview.mobile {
  flex: 0 1 224px;
  min-height: 224px;
  max-height: 224px;
}
</style>
