/* eslint-disable */
const ABI = require('./ABI');
module.exports = {
    ETH: {
        ID: 1,
        symbol: 'ETH',
        RPC: 'https://rpc.ankr.com/eth',
        explorer: 'https://etherscan.io/',
        icon: 'bnb.png',
        baseGas: 1,
        WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        USDPAIR: {
            path: ['0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2','0xdAC17F958D2ee523a2206206994597C13D831ec7'],
            reversedPath: ['0xdAC17F958D2ee523a2206206994597C13D831ec7','0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2']
        },
        USDPAIRPACKED: {
            path: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc20001f4dac17f958d2ee523a2206206994597c13d831ec7',
            reversedPath: '0xdac17f958d2ee523a2206206994597c13d831ec70001f4c02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
        },
        swaps: {
            UniswapV2: {
                name: 'UniswapV2',
                address: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
                factory: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
                ABI: "pancakeswapv2",
                ABIFactory: "pancakeswapv2Factory",
                icon: 'pancakeswap.png',
                default: true
            },
            UniswapV3: {
                name: 'UniswapV3',
                address: '0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45',
                factory: '0x1F98431c8aD98523631AE4a59f267346ea31F984',
                quoter:'0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6',
                ABI: "uniswapV3Router",
                ABIFactory: "uniswapV3Factory",
                ABIQuoter:"uniswapV3Quoter",
                icon: 'pancakeswap.png',
                default: false
            }
        },
        settings: {
            trading: true,
            limitOrders: false,
            frontrun: false, 
            sniping: false
        },
        pairs: ['0xc00e94cb662c3520282e6f5717214004a7f26888',
        '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
        '0xdac17f958d2ee523a2206206994597c13d831ec7',
        '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
        '0x6b175474e89094c44da98b954eedeac495271d0f',
        '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'],
        pairsTagsLocale: {
            '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48':"USDC",
            '0xdac17f958d2ee523a2206206994597c13d831ec7':"USDT",
            '0x6b175474e89094c44da98b954eedeac495271d0f':"DAI"
            },
        pairsTags:{
            '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599':"BTCB",
            '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2':"WETH"
            },
        USDAddress:"0xdAC17F958D2ee523a2206206994597C13D831ec7"
    },
    BNB: {
        ID: 56,
        symbol: 'BNB',
        RPC: 'https://bsc-dataseed.binance.org',
        explorer: 'https://bscscan.com/',
        icon: 'bnb.png',
        baseGas: 1,
        WETH:'0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        USDPAIR: {
            path: ['0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c','0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'],
            reversedPath: ['0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56','0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c']
        },
        swaps: {
            PancakeSwapV2: {
                name: 'PancakeSwap',
                address: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
                factory: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
                ABI: "pancakeswapv2",
                ABIFactory: "pancakeswapv2Factory",
                icon: 'pancakeswap.png',
                default: true
            }
        },
        settings: {
            trading: true,
            limitOrders: true,
            frontrun: true, 
            sniping: false
        },
        pairs: ['0xe9e7cea3dedca5984780bafc599bd69add087d56',
        '0x52ce071bd9b1c4b00a0b92d298c512478cad67e8',
        '0x26a5dfab467d4f58fb266648cae769503cec9580',
        '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
        '0x55d398326f99059ff775485246999027b3197955',
        '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
        '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
        '0x2170ed0880ac9a755fd29b2688956bd959f933f8'],
        pairsTagsLocale: {
            '0xe9e7cea3dedca5984780bafc599bd69add087d56':"BUSD",
            '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d':"USDC",
            '0x55d398326f99059ff775485246999027b3197955':"USDT",
            '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3':"DAI"
            },
        pairsTags:{
            '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c':"WBNB",
            '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c':"BTCB",
            '0x2170ed0880ac9a755fd29b2688956bd959f933f8':"ETH"
            },
        USDAddress:"0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
    },
    DOGE: {
        ID: 2000,
        symbol: 'DOGE',
        RPC: 'https://rpc.dogechain.dog',
        explorer: 'https://explorer.dogechain.dog/',
        icon: 'doge.png',
        warn: 'DOGECHAIN CURRENTLY SUPPORTING ONLY TOKEN/DOGE LIQUIDITY PAIRS',
        baseGas: 10,
        swaps: {
            DogeSwap: {
                name: 'DogeSwap',
                address: '0xa4EE06Ce40cb7e8c04E127c1F7D3dFB7F7039C81',
                factory: '0xD27D9d61590874Bf9ee2a19b27E265399929C9C3',
                ABI: "dogeswapv2",
                ABIFactory: "dogeswapv2Factory",
                icon: 'dogeswap.png',
                default: true
            },
            YodeSwap: {
                name: 'YodeSwap',
                address: '0x72d85Ab47fBfc5E7E04a8bcfCa1601D8f8cE1a50',
                factory: '0xAaA04462e35f3e40D798331657cA015169e005d7',
                ABI: "dogeyodeswap",
                ABIFactory: "dogeyodeswapFactory",
                icon: 'yodeswap.png',
                default: false
            }
        },
        settings: {
            trading: true,
            limitOrders: true,
            frontrun: false, 
            sniping: false
        }
    },
    getChain: function(chainID) {
        switch(chainID) {
            case 56:
                return this.BNB;
            case 1:
                return this.ETH;
            default:
                return this.BNB;
        }
    }, 
    getDefaultSwap: function(chain) {
        const keys = Object.keys(chain.swaps);
        for(let i=0; i< keys.length; i++) {
            if(chain.swaps[keys[i]].default) return chain.swaps[keys[i]];
        }
    }, 
    chainMongoCurrentBlockName: function(chainID) {
        switch(chainID) {
            case 56:
                return 'currentBlock';
            case 1:
                return 'currentBlockETH';
            default:
                return 'currentBlock';
        }
    }
}