<template>
 <div :active="active" :state="state" class="order-holder" :class="[classPlatform]">
  <div class="orderholder order" :class="['',classPlatform]">
    <div class="leftcol">
      <div class="leftcol-inner">
        <button-transparent
        :chainIcon="item.settings.tech.chainID"
        @click="search(item.settings.token.address, true, {chain: item.settings.tech.chainID, address:item.settings.token.address})"
        :text="item.settings.token.symbol.substring(0, 4)"
        :state="states.ButtonTransparentStates.tertiary" />
        <text-comp v-if="item.trade.limitTrade&&parseFloat(item.trade.outputs.gained) != 0" small
        :text="(parseFloat(item.trade.outputs.gained).toFixed(2).toString() + '%')" :wrap="states.TextStatesStyles.BaseText.Wrap.full"
        :color="isPositiveValue(parseFloat(item.trade.outputs.gained))?states.TextStatesStyles.BaseText.Color.green:states.TextStatesStyles.BaseText.Color.red" />
      </div>
      <div class="middlecol">
        <div class="middlecol-inner">
          <div class="data">
            <span>
              <text-comp small :text="``"
              :wrap="states.TextStatesStyles.BaseText.Wrap.full"
              :color="classColorTradeTypeDependend" />
              <span class="nopadding-left-right">
                <text-comp small :text="`${item.trade.sell?'-':'+'}`"
                :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="classColorTradeTypeDependend" />
                <text-comp small :text="item.trade.sell?
                getFloatWithDoublePrecision(parseFloat(fromBNtoString(item.trade.outputs.in, item.settings.token.decimals)), true, true):
                getFloatWithDoublePrecision(parseFloat(fromBNtoString(item.trade.outputs.out, item.settings.token.decimals)), true, true)"
                :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="classColorTradeTypeDependend" />
              </span>
            </span>
          </div>
          <div class="data">
            <span>
              <text-comp small :text="``"
              :wrap="states.TextStatesStyles.BaseText.Wrap.full"
              :color="classColorTradeTypeDependendReverted" />
              <span class="nopadding-left-right">
                <text-comp small :text="`${item.trade.sell?'+':'-'}`"
                :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="classColorTradeTypeDependendReverted" />
                <text-comp small :text="item.trade.sell?
                getFloatWithDoublePrecision(parseFloat(fromBNtoString(item.trade.outputs.out, 18)), true, true):
                getFloatWithDoublePrecision(parseFloat(fromBNtoString(item.trade.outputs.in, 18)), true, true)"
                :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="classColorTradeTypeDependendReverted" />
              </span>
            </span>
            <text-comp small :text='isBNB(item.settings.tech.chainID)?"BNB":"ETH"' :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp small :text="getOrderType"
            :wrap="states.TextStatesStyles.BaseText.Wrap.full"
            :color="states.TextStatesStyles.BaseText.Color.default" />
          </div>
          <div v-if="!this.collapsed&&item&&item.user.wallet.address" class="data overflow-hidden">
            <text-comp small text="wallet:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value wall" :class="[classPlatform, classTablet]">
              <text-comp small :text="getWalletAddressOrTag(item.user.wallet.address)" :wrap="states.TextStatesStyles.BaseText.Wrap.truncated" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
          <div v-show="!this.collapsed" class="data">
            <text-comp small text="slippage:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <text-comp small
              :text="item&&item.settings.tech.initial.slippage?(item.settings.tech.initial.slippage == 100?'MAX':item.settings.tech.initial.slippage):'AUTO'"
              :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
          <div v-show="!this.collapsed&&isBNB(item.settings.tech.chainID)" class="data">
            <text-comp small text="gas:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <text-comp small
              :text="item.settings.tech.initial.gas"
              :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
          <div v-show="!this.collapsed&&item&&item.settings.tech.executed.tax" class="data">
            <text-comp small text="token tax:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <text-comp small
              :text="item?item.settings.tech.executed.tax:'undefined'"
              :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
          <div v-show="!this.collapsed" class="data">
            <text-comp small text="tx:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <a class="prevent-select pointer" :href="(isBNB(item.settings.tech.chainID)?'https://bscscan.com/tx/':item.settings.tech.chainID == 8453?'https://basescan.org/tx/':(item.settings.tech.chainID==1?'https://etherscan.io/tx/':'https://sepolia.etherscan.io/tx/')) + item.trade.txHash" target="blank">
                <text-comp small text="<tx hash>" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lastcol" :class="[classPlatform]">
      <div class="lastcol-inner">
        <text-comp small :text="item.timestamp?toUpperCase(timeConverter(item.timestamp)):'TIME: N/A'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
        <div class="collapse-button" :class="[classPlatform, classCollapsed]">
          <icon-comp flexible :state="states.IconTypes.eraseexpand" @click="collapseHandler()"/>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import ButtonTransparent from './ButtonTransparent.vue';
import TextComp from './TextComp.vue';
import IconComp from './IconComp.vue';

export default {
  name: 'OrderHistory',
  components: {
    ButtonTransparent,TextComp,IconComp
  },
  inject: injects.getInjects(),
  props: ["active","state", "item", "index"],
  data () {
    return {
      states:states,
      collapsed: true
    }
  },
  computed: {
    getOrderType() {
      return this.item.trade.limitTrade?
      (this.item.trade.execOverride?('LIMIT '+(this.item.trade.split?'SPLIT ':'')+(this.item.trade.sell?'SELL ':'BUY ')+'MANUALLY'):('LIMIT '+(this.item.trade.split?'SPLIT ':'')+(this.item.trade.sell?'SELL ':'BUY ')+(this.item.trade.triggers.sl?'SL':(this.item.trade.triggers.tp?'TP':(this.item.trade.triggers.bp?'TARGET PRICE':''))))):
        "Manually"
    },
    classColorTradeTypeDependend() {
      return {
        'red': this.item.trade.sell,
        'green': !this.item.trade.sell
      }
    },
    classColorTradeTypeDependendReverted() {
      return {
        'red': !this.item.trade.sell,
        'green': this.item.trade.sell
      }
    },
    classCollapsed() {
      return {collapsed: this.collapsed}
    }
  },
  methods: {
    collapseHandler() {
      this.collapsed = !this.collapsed;
    },
  }
}
</script>
<style scoped>
.order-holder.desktop {
  padding-bottom: 8px;
}
.order-holder.mobile {
  padding-bottom: 8px;
}
.orderholder {
  padding: 4px;
  border: 1px solid;
  border-color: var(--sepia-skin-2);
  border-radius: 2px;
  flex: 1;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
}
.orderholder.mobile {
  padding: 4px;
}
.orderholder.order {
  align-items: flex-start;
  display: flex;
  position: relative;
}
.leftcol {
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
}
.leftcol-inner {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: fit-content;
}
.middlecol {
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
}
.middlecol-inner{
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
}
.lastcol {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: fit-content;
  right: 4px;
}
.lastcol.mobile {
  right: 2px;
  top: 0px;
}
.lastcol-inner {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0px 6px 0px 0px;
  position: relative;
  width: fit-content;
}
.data {
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
  flex-wrap: wrap;
}
.data.overflow-hidden {
}
.value {
  align-items: center;
  display: flex;
  position: relative;
  width: fit-content;
}
.value.wall {
  max-width: 50vw;
}
.value.wall.desktop,
.value.wall.tablet {
  max-width: 17vw;
}
.collapsed{
  visibility: hidden;
}
.collapse-button {
  transform: rotate(90deg);
  display: flex;
}
.collapse-button.mobile {
  width: 15px;
  height: 15px;
}
.collapse-button.collapsed {
  visibility: visible;
  transform: rotate(-90deg);
}
span {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  position: relative;
}
</style>
