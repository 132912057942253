<template>
 <div :active="active" :connected="connected" class="buypanelcontent" :class="[classPlatform]">
  <div v-if="isSnipe" class="searchbar-holder" :class="[classPlatformTabletOverride]">
    <search-bar :state="'snipe'" />
  </div>
  <div class="holder" :class="[classPlatform]">
    <div class="amount-gas-slip-setup" :class="[classPlatform, classSnipe]">
      <div class="amount" :class="[classPlatform]">
        <div class="amount-slider-group" :class="[classPlatform]">
          <percentages-comp :state="states.Percentages.Type.zerofullrange" />
          <slider-comp :state="states.Slider.Type.full" :gallery="'buy'" :labelval="'buyPercentage'"/>
        </div>
        <div class="input-switch" :class="[classPlatform]">
          <div class="inputs" :class="[classPlatform]">
            <div class="input-scaler" :class="[classPlatform, computedIsPanelBuying?'order-1':'order-2']">
              <div class="input-group" :class="[classPlatform]">
                <input-comp
                  placeh="0"
                  class="inputamt"
                  :class="inputClass"
                  :name="'amount'"
                  :value="amount"
                  @inputHandler="inputFieldHandler"
                  :chainEvent="'swapHandler'"
                  @focus="focusHandler(false)" />
                <text-comp
                  :text="computedWETH" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
              </div>
            </div>
            <div class="input-scaler" v-show="!isSnipe" :class="[classPlatform, computedIsPanelBuying?'order-2':'order-1']">
              <div class="input-group" :class="[classPlatform]">
                <input-comp
                  placeh="0"
                  class="inputamt"
                  :class="inputClass"
                  :name="'sellamount'"
                  :value="sellamount"
                  @inputHandler="inputFieldHandler"
                  :chainEvent="'swapHandler'"
                  @focus="focusHandler(true)" />
                <text-comp
                  :text="this.currentToken?this.currentToken.smb3:'TKN'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
              </div>
            </div>
          </div>
          <div v-show="!isSnipe" class="icon-swap-holder prevent-select pointer" @click="switchPanelBuying()" :class="[classPlatform]">
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" class="icon-button">
              <path style="fill:var(--jade)" d="M12.9841 4.99255C12.9841 4.44027 13.4318 3.99255 13.9841 3.99255C14.3415 3.99255 14.6551 4.18004 14.8319 4.46202L17.5195 7.14964C17.9101 7.54016 17.9101 8.17333 17.5195 8.56385C17.129 8.95438 16.4958 8.95438 16.1053 8.56385L14.9841 7.44263V14.9926C14.9841 15.5448 14.5364 15.9926 13.9841 15.9926C13.4318 15.9926 12.9841 15.5448 12.9841 14.9926V5.042C12.984 5.03288 12.984 5.02376 12.9841 5.01464V4.99255Z" fill="currentColor"></path>
              <path style="fill:var(--cedar-chest)" d="M11.0159 19.0074C11.0159 19.5597 10.5682 20.0074 10.0159 20.0074C9.6585 20.0074 9.3449 19.82 9.16807 19.538L6.48045 16.8504C6.08993 16.4598 6.08993 15.8267 6.48045 15.4361C6.87098 15.0456 7.50414 15.0456 7.89467 15.4361L9.01589 16.5574V9.00745C9.01589 8.45516 9.46361 8.00745 10.0159 8.00745C10.5682 8.00745 11.0159 8.45516 11.0159 9.00745V18.958C11.016 18.9671 11.016 18.9762 11.0159 18.9854V19.0074Z" fill="currentColor"></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="settings-sliders" :class="[classPlatform]">
        <gas-preset-selector />
        <div v-show="!isSnipe" class="gasFee" :class="classPlatform">
          <text-comp :text="'GAS FEE:'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
          <text-comp :text="txCost" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
        </div>
        <div v-show="!isSnipe" class="gasFee" :class="classPlatform">
          <text-comp :text="'SAFE BAL:'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
          <text-comp :text="+minBalToSend+' '+computedWETH" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
        </div>
        <div v-if="!isBNB(chain.ID)||isSnipe" class="gasFee" :class="classPlatform">
          <text-comp :text="isSnipe?'RETRY:':'MEV:'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
          <div class="gasFee" :class="classPlatform">
            <button-transparent
            v-if="computedUseMev"
            :active="!computedDisableRetry?true:null"
            :text="`${isSnipe?'USE':'RETRY'}:${!computedDisableRetry?'on':'off'}`"
            :state="states.ButtonTransparentStates.tertiary" :color="states.TextStatesStyles.BaseText.Color.default"
            @click="changeDisableRetry()"/>
            <button-transparent v-if="!isSnipe"
            :active="computedUseMev?true:null"
            :text="`use:${computedUseMev?'on':'off'}`"
            :state="states.ButtonTransparentStates.tertiary" :color="states.TextStatesStyles.BaseText.Color.default"
            @click="changeMEV()"/>
          </div>
        </div>
        <settings-slider v-show="isSnipe" text="gas" />
        <settings-slider v-show="!isSnipe" text="slippage" />
      </div>
      <div class="button-holder" :class="[classPlatform]">
        <button-transparent
        :active="computedBotOn?true:null"
        :text="!isSnipe?
        `${this.approveLabel(computedIsPanelBuying?false:true)} ${this.currentToken?this.currentToken.smb4:'NAME'}`:
        'LAUNCH ASS'"
        :state="states.ButtonTransparentStates.connect"
        :color="computedIsPanelBuying?
        states.TextStatesStyles.BaseText.Color.green:
        states.TextStatesStyles.BaseText.Color.red"
        @click="computedIsPanelBuying?trade(false, isSnipe?true:false):trade(true)" />
      </div>
    </div>
    <div class="limits-holder" :class="[classPlatform,classSnipe]">
      <div class="limits" :class="[classPlatform]">
        <div v-show="!isSnipe" class="limits-slider-group" :class="[classPlatform]">
          <div class="header" :class="[classPlatform]">
            <button-transparent
            :active="computedUseBP?true:null"
            :text="`buy price:${computedUseBP?'on':'off'}`"
            :state="states.ButtonTransparentStates.tertiary" :color="states.TextStatesStyles.BaseText.Color.default"
            @click="changeBP()"/>
          </div>
          <slider-comp
            wide
            :gallery="'bp'"
            inputactive
            :state="states.Slider.Type.full"
            :class="computedUseBP?'':'hidden'"
            :name="'priceTriggerAmount'"
            :value="priceTriggerAmount"
            :chainEvent="'amountCheck'"
            :chainEventArgs="false"
            :clickoutside="'amountBPFocusLost'"
            />
        </div>
        <div class="limits-slider-group" :class="[classPlatform]">
          <div class="header" :class="[classPlatform]">
            <button-transparent
            :active="computedUseTP?true:null"
            :text="`take profit:${computedUseTP?'on':'off'}`"
            :state="states.ButtonTransparentStates.tertiary" :color="states.TextStatesStyles.BaseText.Color.default"
            @click="changeTP()"/>
            <percentages-comp paddingzero v-show="computedUseTP" :state="states.Percentages.Type.takeprofit" />
          </div>
          <slider-comp
            :gallery="'tp'"
            inputactive
            :state="states.Slider.Type.full"
            :class="computedUseTP?'':'hidden'"
            :name="'takeProfit'"
            :value="takeProfit"
            :chainEvent="'amountCheck'"
            :chainEventArgs="true"
            :clickoutside="'amountTPFocusLost'"
            />
        </div>
        <div class="limits-slider-group" :class="[classPlatform]">
          <div class="header" :class="[classPlatform]">
            <button-transparent
            :active="computedUseSL?true:null"
            :text="`stop loss:${computedUseSL?'on':'off'}`"
            :state="states.ButtonTransparentStates.tertiary" :color="states.TextStatesStyles.BaseText.Color.default"
            @click="changeSL()"/>
            <percentages-comp paddingzero v-show="computedUseSL" :state="states.Percentages.Type.stoploss" />
          </div>
          <slider-comp :state="states.Slider.Type.full"
          :class="computedUseSL?'':'hidden'"
          :gallery="'sl'"
          :labelval="'stopLoss'"/>
          <div v-show="computedUseSL" class="header pointer prevent-select" :class="[classPlatform,computedUseSL?'':'hidden']" @click="changetSL()">
            <text-comp text="trailing:" :wrap="states.TextStatesStyles.BaseText.Wrap.full"
              :color="!computedtSL?states.TextStatesStyles.BaseText.Color.default:states.TextStatesStyles.BaseText.Color.dark" />
            <button-transparent
              :active="computedtSL?true:null"
              :text="computedtSL?'use:on':'use:off'"
              :state="states.ButtonTransparentStates.tertiary" />
          </div>
        </div>
      </div>
      <div class="data-holder" :class="[classPlatform]">
        <div v-show="!isSnipe" class="labels" :class="[classPlatform]">
          <div class="left-column" :class="[classPlatform]">
            <text-comp
              :text="`LP: ${this.LP}`" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp
              :text="`MC: ${this.MCAP.toLocaleString('en-US')}$`" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <span>
              <text-comp text=" PRICE:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
              <span class='nopadding-left-right pointer' @click="useUSDPrice">
                <text-comp
                  :text="getFloatWithDoublePrecision(this.USDPrice, true)" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark"/>
                <text-comp text="$" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
              </span>
            </span>
            <text-comp
            :text="computedRoute?('ROUTE: ' + computedRoute):'ROUTE'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="!isConnected" class="not-connected" :class="[classPlatform]">
    <div class="valign-text-middle logo" :class="[classPlatform, classLogoText]">
      <h1>FAST TRADING TERMINAL<br />POWERED BY <a target='blank' href='https://def.cafe' class="blue-link">DEF.CAFE</a></h1>
    </div>
    <flag-rectangle />
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import IconComp from '../basic/IconComp.vue';
import TextComp from '../basic/TextComp.vue'
import ButtonTransparent from '../basic/ButtonTransparent.vue';
import SliderComp from '../basic/SliderComp.vue';
import SettingsSlider from '../basic/SettingsSlider.vue';
import PercentagesComp from '../basic/PercentagesComp.vue';
import FlagRectangle from '../basic/FlagRectangle.vue';
import InputComp from '../basic/InputComp.vue';
import GasPresetSelector from '../basic/GasPresetSelector.vue';
import SearchBar from '../basic/SearchBar.vue';

export default {
  name: 'BuyPanelContent',
  components: {
    IconComp,ButtonTransparent,TextComp,SliderComp,SettingsSlider,
    PercentagesComp,
    FlagRectangle,InputComp,GasPresetSelector,SearchBar
  },
  inject: injects.getInjects(),
  props: ["active","state","status","connected"],
  data () {
    return {
      states: states
    }
  },
  computed: {
    classLogoText() {
      return this.classPlatform.desktop?
              states.TextStatesStyles.DisconnectedFTT.desktop:states.TextStatesStyles.DisconnectedFTT.mobile;
    },
    isSnipe() {
      return this.showLogSection('snipe');
    },
    classSnipe() {
      return this.isSnipe?'snipe':'';
    },
    isConnected() {
      return this.connected != null;
    },
    inputClass() {
      return this.classPlatform.desktop?states.TextStatesStyles.BaseText.desktop.default:states.TextStatesStyles.BaseText.mobile.default;
    },
    isLimit() {
      return this.computedUseBP || !this.computedIsPanelBuying&&(this.computedUseTP || this.computedUseSL);
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.blue-link {
  color: var(--blue-link);
  display: inline;
}
.buypanelcontent {
  display: flex;
  position: relative;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  gap:0px;
  height: 100%;
  flex: 1;
}
.buypanelcontent.desktop {
}
.buypanelcontent.mobile {
}
.buypanelcontent .not-connected {
  justify-content: flex-end;
  background-color: var(--bon-jour);
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}
.buypanelcontent .not-connected.desktop {
  gap: 41px;
  padding-bottom: 34px;
}
.buypanelcontent .not-connected.mobile {
  gap: 10px;
  padding-bottom: 18px;
}
.buypanelcontent .not-connected .logo {
  letter-spacing: 0;
  /**line-height: normal;**/
}
.buypanelcontent .not-connected .logo.desktop {
  height: 76px;
  margin-left: 42px;
}
.buypanelcontent .not-connected .logo.mobile {
  height: 40px;
  margin-left: 18px;
}
.buypanelcontent .holder {
  align-items: flex-start;
  display: flex;
  flex: 1;
  position: relative;
}
.buypanelcontent .holder.desktop {
}
.buypanelcontent .holder.mobile {
}
.buypanelcontent .holder .amount-gas-slip-setup {
  align-items: center;
  align-self: stretch;
  border-color: var(--sepia-skin);
  border-right-style: solid;
  border-right-width: 1px;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}
.buypanelcontent .holder .amount-gas-slip-setup.desktop {
  gap: 8px;
  padding: 10px 0px;
}
.buypanelcontent .holder .amount-gas-slip-setup.mobile {
  padding: 3px 0px 0px 0px;
}
.buypanelcontent .holder .amount-gas-slip-setup.mobile.snipe {
  padding: 3px 0px 0px 0px;
  padding-top: 10px;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount {
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  display: flex;
  position: relative;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount.desktop {
  gap: 8px;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount.mobile {
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .amount-slider-group {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .amount-slider-group.desktop {
  gap: 4px;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .amount-slider-group.mobile {
  gap: 4px;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch{
  align-items: center;
  justify-content: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch.desktop {
  padding: 0px 6px;  
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch.mobile {
  padding: 0px 3px;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs.desktop {
  gap: 4px; 
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs.mobile {
  padding: 6px 0px 0px 0px;  
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .icon-swap-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--sepia-skin);
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .icon-swap-holder.mobile {
  width: 26px;
  height: 26px;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .icon-swap-holder.desktop {
  width: 36px;
  height: 36px;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .icon-swap-holder svg {
  width: 100%;
  height: 100%;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs .input-scaler {
  align-self: stretch;
  display: flex;
  position: relative;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs .input-scaler.order-1{
  order: 0;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs .input-scaler.order-2{
  order: 1;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs .input-scaler.desktop {
  height: 24px;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs .input-scaler.mobile {
  height: 18px;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs .input-scaler .input-group {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex: 1;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs .input-scaler .input-group.desktop {
  height: 24px;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs .input-scaler .input-group.mobile {
  height: 15px;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs .input-scaler .input-group .inputamt {
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs .input-scaler .input-group .inputamt.desktop {
  padding:0px 5px 0px 0px;
}
.buypanelcontent .holder .amount-gas-slip-setup .amount .input-switch .inputs .input-scaler .input-group .inputamt.mobile {
  padding:0px 4px 0px 0px;
}
.buypanelcontent .holder .amount-gas-slip-setup .settings-sliders {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
}
.buypanelcontent .holder .amount-gas-slip-setup .settings-sliders .gasFee {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
.buypanelcontent .holder .amount-gas-slip-setup .settings-sliders.desktop {
  gap: 4px;
}
.buypanelcontent .holder .amount-gas-slip-setup .settings-sliders.mobile {
  gap: 2px;
}
.buypanelcontent .holder .amount-gas-slip-setup .settings-sliders span {
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  position: relative;
}
.buypanelcontent .holder .amount-gas-slip-setup .button-holder {
  align-items: center;
  align-self: stretch;
  justify-content: center;
  display: flex;
  flex: 1;
  position: relative;
}
.buypanelcontent .holder .amount-gas-slip-setup .button-holder .mobile {
}
.buypanelcontent .holder .amount-gas-slip-setup .button-holder .desktop {
}
.buypanelcontent .holder .limits-holder {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-color: var(--sepia-skin);
  border-left-style: solid;
  border-left-width: 1px;
  flex: 1;
  position: relative;
}
.buypanelcontent .holder .limits-holder.desktop {
  padding: 10px 0px 4px;
}
.buypanelcontent .holder .limits-holder.mobile {
  padding: 4px 0px 0px 0px;
}
.buypanelcontent .holder .limits-holder.mobile.snipe {
  padding-top: 10px;
}
.buypanelcontent .holder .limits-holder .limits{
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column; 
  position: relative;
}
.buypanelcontent .holder .limits-holder .limits.desktop {
  gap: 4px;
}
.buypanelcontent .holder .limits-holder .limits.mobile {
  gap: 3px;
}
.buypanelcontent .holder .limits-holder .limits .limits-slider-group {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
}
.buypanelcontent .holder .limits-holder .limits .limits-slider-group.desktop {
  gap: 4px;
}
.buypanelcontent .holder .limits-holder .limits .limits-slider-group.mobile {
  gap: 3px;
}
.buypanelcontent .holder .limits-holder .limits .limits-slider-group .header {
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  position: relative;
}
.buypanelcontent .holder .limits-holder .limits .limits-slider-group .header.desktop {
}
.buypanelcontent .holder .limits-holder .limits .limits-slider-group .header.mobile {
}
.buypanelcontent .holder .limits-holder .data-holder{
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  position: relative;
  gap: 10px;
}
.buypanelcontent .holder .limits-holder .data-holder .labels{
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  gap: 10px;
  position: relative;
}
.buypanelcontent .holder .limits-holder .data-holder .labels.desktop {
  padding: 0px 0px;
  
}
.buypanelcontent .holder .limits-holder .data-holder .labels.mobile{
}
.buypanelcontent .holder .limits-holder .data-holder .labels .left-column{
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}
.buypanelcontent .holder .limits-holder .data-holder .labels .left-column span{
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  position: relative;
}
.buypanelcontent .holder .limits-holder .data-holder .labels .left-column.desktop {
  gap: 2px;
}
.buypanelcontent .holder .limits-holder .data-holder .labels .left-column.mobile{
}
.searchbar-holder {
  display: flex;
  width: 100%;
  padding-right: 0px;
}
.searchbar-holder.desktop {
  align-items: center;
}
</style>
