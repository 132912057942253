<template>
 <div :index="index" :item="item" :active="active" :deleted="deleted" class="walletscontextmenu" :class="[classPlatform]" v-click-out-side="hideThis">
  <button-transparent text="copy wallet address" :state="states.ButtonTransparentStates.tertiary"  @click="copyAddress(item.address)" />
  <button-transparent v-if="!item.decodedKey" text="request private key" :state="states.ButtonTransparentStates.tertiary" @click="exportKey(index)" />
  <button-transparent v-if="item.decodedKey" text="copy private key" :state="states.ButtonTransparentStates.tertiary" @click="copyKey(item.decodedKey)" />
  <button-transparent v-if="!isDeleted" text="rename wallet" :state="states.ButtonTransparentStates.tertiary" @click="renameWallet(index)" />
  <button-transparent v-if="!isDeleted" text="delete wallet" :state="states.ButtonTransparentStates.tertiary" @click="confirmDeleteWallet(index)"/>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import ButtonTransparent from '../basic/ButtonTransparent.vue';
export default {
  name: 'WalletsContextMenu',
  directives: {
    clickOutSide,
  },
  components: {
    ButtonTransparent,
  },
  inject: injects.getInjects(),
  props: ["active", "deleted", "item", "index"],
  data () {
    return {
      states:states,
      clicks: 0,
    }
  },
  computed: {    
    isDeleted() {
      return this.deleted != null
    }
  },
  methods: {
    hideThis(e) {
      this.clicks++;
      if(this.clicks > 1) {
        this.clicks = 0;
        this.hideOptionsPopUp(e);
      }
    }
  }
}
</script>
<style scoped>
.walletscontextmenu {
  align-items: flex-start;
  background-color: var(--bon-jour);
  border: 1px solid;
  border-color: var(--sepia-skin-2);
  border-radius: 2px;
  box-shadow: 4px 4px 0px var(--sepia-skin-2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 8px;
  position: relative;
  width: fit-content;
}
.walletscontextmenu.desktop {
}
.walletscontextmenu.mobile {
}
</style>
