<template>
 <div :active="active" :state="state" class="order-holder" :class="[classPlatform]">
  <div class="orderrunning order width100">
    <div class="leftcol width100">
      <div class="leftcol-inner flexWrap">
         <button-transparent
          :chainIcon="item.trade.settings.tech.chainID"
          @click="search(item.trade.settings.token.address, true, {chain: item.trade.settings.tech.chainID, address:item.trade.settings.token.address})"
          :text="toUpperCase(item.trade.settings.token.symbol)"
          :state="states.ButtonTransparentStates.tertiary" />
        <text-comp :text="`${toUpperCase(item.status)}${item.trade.out.split?' (SPLIT)':''}`"
                    :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                    :color="states.TextStatesStyles.BaseText.Color.dark" />
        <text-comp text="market" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
        <text-comp :text="this.state" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color[(this.state == 'sell'?'red':'green')]" />
        <text-comp v-if="item.trade.settings.tech.mev"
        :text="'MEV'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.green" />
      </div>
      <div class="middlecol width100">
        <div class="midlecol-inner width100">
          <div v-if="item&&item.trade.user.wallet.address" class="data">
            <text-comp text="wallet:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value wall" :class="[classPlatform, classTablet]">
              <text-comp :text="getWalletAddressOrTag(item.trade.user.wallet.address)" :wrap="states.TextStatesStyles.BaseText.Wrap.truncated" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
          <div v-if="!item.trade.out.results" class="data">
            <text-comp text="amount:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <text-comp :text='getFloatWithDoublePrecision(parseFloat(
                (fromBNtoString(item.trade.settings.amounts.amount,item.trade.settings.trade.sell?item.trade.settings.token.decimals:18)), true, true))'
              :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
              <text-comp :text='`${!item.trade.settings.trade.sell?((isBNB(item.trade.settings.tech.chainID)?" BNB":" ETH")):""}`' :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
          <div v-if="item.trade.out.results" class="data">
            <text-comp text="spent:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <text-comp small :text="getFloatWithDoublePrecision(parseFloat(fromBNtoString(item.trade.out.results.trade.outputs.in, item.trade.settings.trade.sell?item.trade.settings.token.decimals:18)), true, true)"
                :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="classColorTradeTypeDependendReverted" />
              <text-comp :text='`${!item.trade.settings.trade.sell?((isBNB(item.trade.settings.tech.chainID)?" BNB":" ETH")):item.trade.settings.token.symbol}`' :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
           <div v-if="item.trade.out.results" class="data">
            <text-comp text="filled:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <text-comp small :text="getFloatWithDoublePrecision(parseFloat(fromBNtoString(item.trade.out.results.trade.outputs.out, item.trade.settings.trade.sell?18:item.trade.settings.token.decimals)), true, true)"
                :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="classColorTradeTypeDependend" />
              <text-comp :text='`${item.trade.settings.trade.sell?((isBNB(item.trade.settings.tech.chainID)?" BNB":" ETH")):item.trade.settings.token.symbol}`' :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
          <div v-if="item.trade.out.results" class="complexdata" :class="classState">
              <div v-if="item.trade.out.tiedOrder&&item.trade.out.tiedOrder.place" class="trigger">
              <text-comp text="tied trade:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
                <div class="databuy">
                  <div v-if="(item.trade.settings.limits.tp.use||item.trade.settings.limits.sl.use)" class="data">
                    <text-comp text="place" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
                    <text-comp text="sell" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.red" />
                    <text-comp text="order" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
                  </div>
                  <div class="data">
                    <text-comp v-if="item.trade.settings.limits.sl.use"
                      :text="`${item.trade.settings.limits.sl.use?(item.trade.settings.limits.sl.trail?'t/':''):''} LOSS > -${item.trade.settings.limits.sl.amount}%`" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.red" />
                    <text-comp v-if="item.trade.settings.limits.tp.use&&item.trade.settings.limits.sl.use" text="or" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
                    <text-comp v-if="item.trade.settings.limits.tp.use"
                      :text="`GAIN > +${item.trade.settings.limits.tp.amount}%`" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.green" />
                  </div>
              </div>
            </div>
          </div>
          <div v-if="item.tx||item.trade.out.results" class="data">
            <text-comp text="tx:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <a class="prevent-select pointer"
              :href="item.tx||item.trade.out.results?(isBNB(item.trade.settings.tech.chainID)?'https://bscscan.com/tx/':item.trade.settings.tech.chainID == 8453?'https://basescan.org/tx/':`https://${item.trade.settings.tech.chainID != 1?'sepolia.':''}etherscan.io/tx/`) + (item.trade.out.results?item.trade.out.results.trade.txHash:item.tx):'#'"
              :target="(item.tx||item.trade.out.results)?'blank':''" >
                <text-comp
                :text="item.tx||item.trade.out.results?'<TX HASH>':'N/A'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
              </a>
            </div>
          </div>
          <div v-if="item.error" class="data error">
            <text-comp :text="item.error" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :align="'left'" :color="states.TextStatesStyles.BaseText.Color.red" />
          </div>
        </div>
      </div>
    </div>
    <div class="lastcol">
    </div>
  </div>
 </div>
</template>
<script>
/* eslint-disable */
/*   
 <div class="row1">
        <button-transparent v-if="computeShowExecuteButton(item.uuid)" text="execute" :state="states.ButtonTransparentStates.tertiary"
                            @click="execute(item.uuid)"/>
      </div>
      <div class="row2">
        <button-transparent
        v-if="(item.trade.session)&&!isBNB(item.trade.settings.tech.chainID)"
        :active="isMevProtection(item.uuid)?true:null"
        :text="`mev:${isMevProtection(item.uuid)?'on':'off'}`"
        :state="states.ButtonTransparentStates.tertiary"
        @click="setLimitOrderProtectons(item.uuid, 'mev', !item.trade.settings.tech.mev)" />
        <button-transparent
        v-if="computeShowLimitButton(item.uuid)"
        :text="toUpperCase(getOrderAction(item.uuid))" :state="states.ButtonTransparentStates.tertiary"
        @click="autoOrderAction(item.uuid)"/>
      </div>
*/
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import ButtonTransparent from './ButtonTransparent.vue';
import TextComp from './TextComp.vue';
import IconComp from './IconComp.vue';

export default {
  name: 'OrderMarket',
  components: {
    ButtonTransparent,TextComp,IconComp
  },
  inject: injects.getInjects(),
  props: ["active","state", "item", "index"],
  data () {
    return {
      states:states    }
  },
  computed: {
    classState() {
      return this.state;
    },
    isBuy() {
      return (this.state == states.OrderRunning.LimitType.buy);
    },
    isSell() {
      return (this.state == states.OrderRunning.LimitType.sell);
    },
    classColorTradeTypeDependend() {
      return {
        'red': this.item.trade.settings.trade.sell,
        'green': !this.item.trade.settings.trade.sell
      }
    },
     classColorTradeTypeDependendReverted() {
      return {
         'red': !this.item.trade.settings.trade.sell,
        'green': this.item.trade.settings.trade.sell
      }
    },
  },
  methods: {
    isCurrentGasPreset(preset){
       return this.item&&this.item.trade.session.gasPreset === preset?true:null;
    },
  },
}
</script>
<style scoped>
.order-holder.desktop {
  padding-bottom: 8px;
}
.order-holder.mobile {
  padding-bottom: 8px;
}
.orderrunning {
  border: 1px solid;
  border-color: var(--sepia-skin-2);
  border-radius: 2px;
  padding: 4px;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}
.orderrunning.order {
  align-items: flex-start;
  display: flex;
  position: relative;
}
.leftcol {
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
  flex-direction: column;
}
.leftcol-inner {
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
}
.middlecol {
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
  align-items: flex-start;
}
.middlecol-inner{
  align-items: flex-start;
  justify-content: center;
  display: flex;
  position: relative;
  width: fit-content;
}
.lastcol {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: fit-content;
  top: 0;
  right: 0;
  height: 100%;
}
.row1 {
  align-items: center;
  display: flex;
  gap: 2px;
  justify-content: flex-end;
  position: relative;
  width: fit-content;
}
.row2 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
  width: fit-content;
}
.data {
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
}
.data.error{
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
}
.databuy {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.value {
  align-items: center;
  display: flex;
  position: relative;
  width: 60%;
}
.complexdata {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
  flex-direction: column;
}
.trigger {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
}
.complex {
  align-items: flex-start;
  justify-content: flex-start;
}
.width100 {
  width: 100%;
}
.flexWrap {
  flex-wrap: wrap;
}
.value.wall {
  width: fit-content;
  max-width: 50vw;
}
.value.wall.desktop,
.value.wall.tablet {
  max-width: 17vw;
}
</style>
