<template>
 <div :active="active" :state="state" class="order-holder" :class="[classPlatform]">
  <div class="orderrunning order width100">
    <div class="leftcol width100">
      <div class="leftcol-inner flexWrap">
         <button-transparent v-if="item.smb"
          :text="toUpperCase(item.smb)"
          :state="states.ButtonTransparentStates.tertiary" />
        <text-comp :text="'searching token'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="middlecol width100">
        <div class="midlecol-inner width100">
          <div v-if="item&&currentWallet" class="data">
            <text-comp text="wallet:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <text-comp :text="currentWallet" :wrap="states.TextStatesStyles.BaseText.Wrap.truncated" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
          <div v-if="!item&&item.addr" class="data">
            <text-comp text="address" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <text-comp :text='item.adr'
              :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
          <div v-if="item&&item.apprv" class="data">
            <text-comp text="approve:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <text-comp small :text="item.apprv?'yes':'no'"
                :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
          <div v-if="item&&item.error" class="data error">
            <text-comp :text="item.error" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :align="'left'" :color="states.TextStatesStyles.BaseText.Color.red" />
          </div>
        </div>
      </div>
    </div>
    <div class="lastcol">
    </div>
  </div>
 </div>
 </div>
</template>
<script>
/* eslint-disable */

const injects = require('../../modules/injects')
const states = require('../../modules/states')

import ButtonTransparent from './ButtonTransparent.vue';
import TextComp from './TextComp.vue';

export default {
  name: 'SearchElement',
  components: {
    ButtonTransparent,TextComp
  },
  inject: injects.getInjects(),
  props: ["active","state", "item", "index"],
  data () {
    return {
      states:states    }
  },
  computed: {
    classState() {
      return this.state;
    },
  },
  methods: {
  },
}
</script>
<style scoped>
.order-holder.desktop {
  padding-bottom: 8px;
}
.order-holder.mobile {
  padding-bottom: 8px;
}
.orderrunning {
  border: 1px solid;
  border-color: var(--sepia-skin-2);
  border-radius: 2px;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}
.orderrunning.order {
  align-items: flex-start;
  display: flex;
  position: relative;
}
.leftcol {
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
  flex-direction: column;
}
.leftcol-inner {
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
}
.middlecol {
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
  align-items: flex-start;
}
.middlecol-inner{
  align-items: flex-start;
  justify-content: center;
  display: flex;
  position: relative;
  width: fit-content;
}
.lastcol {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: fit-content;
  top: 0;
  right: 0;
  height: 100%;
}
.row1 {
  align-items: center;
  display: flex;
  gap: 2px;
  justify-content: flex-end;
  position: relative;
  width: fit-content;
}
.row2 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
  width: fit-content;
}
.data {
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
}
.data.error{
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
}
.databuy {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.value {
  align-items: center;
  display: flex;
  position: relative;
  width: 60%;
}
.complexdata {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
  flex-direction: column;
}
.trigger {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
}
.complex {
  align-items: flex-start;
  justify-content: flex-start;
}
.width100 {
  width: 100%;
}
.flexWrap {
  flex-wrap: wrap;
}
</style>
