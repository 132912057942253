<template>
  <div :active="active" :state="state" class="percentagescomp" :class="[classPlatform]">
    <button-transparent v-for="(item,index) in txt[state]" v-bind:key="index" :text="item" :state="states.ButtonTransparentStates.tertiary"
    :class="[paddingzero != null?'padding-left-right-zero':'']"
    @click="this.handlers[state].method(this.handlers[state].values[index])"/>
  </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import ButtonTransparent from './ButtonTransparent.vue'

export default {
  name: 'PercentagesComp',
  components: {
    ButtonTransparent
  },
  inject: injects.getInjects(),
  props: ["active","state", "paddingzero"],
  data() {
    return {
      states:states,
      txt: {
        zerofullrange: ["0","25","50","75","100%"],
        fullrange: ["25","50","75","100%"],
        takeprofit: ["2","3","5","10X"],
        stoploss:["25","50","75%"]
      },
      handlers: {
        zerofullrange: {
          method: this.fillQuick,
          values: [0,25,50,75,100]
        },
        fullrange: ["","","",""],
        takeprofit: {
          method: this.setTP,
          values: [100,200,400,900]
        },
        stoploss:{
          method: this.setSL,
          values: [25,50,75]
        },
      }
    }
  },
  computed: {
    classType() {
      return this.state;
    }
  },
  methods: {
    setTP(val) {
      this.setLimits(val,true);
      this.currentValues[1] = val*5;
    },
    setSL(val) {
      this.setLimits(val,false);
      this.currentValues[2] = val*5;
    }
  }
}
</script>
<style scoped>
.percentagescomp {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-grow: 1;
}
.percentagescomp.mobile {
  padding: 0px 0px 0px 3px;
}
.percentagescomp.desktop {
}
</style>
