<template>
 <div :active="active" class="popupcontentsettings" :class="[classPlatform]">
  <text-comp text="settings:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
  <div class="content" :class="[classPlatform]">
    <div class="data">
      <text-comp text="theme" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="buttons" :class="[classPlatform]">
        <button-transparent :active="dark?true:null" text="dark" :state="states.ButtonTransparentStates.tertiary" @click="switchStyle(true)"/>
        <button-transparent :active="!dark?true:null" text="light" :state="states.ButtonTransparentStates.tertiary" @click="switchStyle(false)"/>
      </div>
    </div>
    <div class="data">
      <text-comp text="chain" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="buttons" :class="[classPlatform]">
        <button-transparent :active="isChainSelected(56)?true:null" text="BSC" :state="states.ButtonTransparentStates.tertiary" @click="switchChain(56)"/>
        <button-transparent :active="isChainSelected(1)?true:null" text="ETH" :state="states.ButtonTransparentStates.tertiary" @click="switchChain(1)"/>
        <button-transparent v-if="!computedIsProduction"
        :active="isChainSelected(11155111)?true:null"
        text="SEPOLIA" :state="states.ButtonTransparentStates.tertiary" @click="switchChain(11155111)"/>
        <button-transparent v-if="!computedIsProduction"
        :active="isChainSelected(8453)?true:null"
        text="BASE" :state="states.ButtonTransparentStates.tertiary" @click="switchChain(8453)"/>
      </div>
    </div>
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import ButtonTransparent from '../basic/ButtonTransparent.vue';
import ButtonFull from '../basic/ButtonFull.vue';
import TextComp from '../basic/TextComp.vue';
export default {
  name: 'PopupContentSettings',
  components: {
    ButtonTransparent,ButtonFull,TextComp
  },
  inject: injects.getInjects(),
  props: ["active"],
  data () {
    return {
      states:states    
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.popupcontentsettings {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}
.popupcontentsettings.desktop {
  gap: 26px;
}
.popupcontentsettings.mobile {
  gap: 14px;
}
.popupcontentsettings .content{
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
}
.popupcontentsettings .content.desktop{
  gap: 16px;
}
.popupcontentsettings .content.mobile{
  gap: 8px;
}
.popupcontentsettings .content .data{
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.popupcontentsettings .content .data .buttons{
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.popupcontentsettings .content .data .buttons.mobile{
  gap: 14px;
}
.popupcontentsettings .content .data .buttons.desktop{
  gap: 25px;
}
</style>
