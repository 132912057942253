<template>
  <div :active="active" :inputactive="inputactive" :text="text" :state="state" class="gaspresetselector" :class="[classPlatform]">
    <div class="leftcol" :class="classPlatform">
      <text-comp text="gas:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <button-transparent :active="isDefaultSetting('gas', 'low')?true:null" text="low" :state="states.ButtonTransparentStates.tertiary" @click="setGasPreset('low')"/>
      <button-transparent :active="isDefaultSetting('gas', 'medium')?true:null" text="medium" :state="states.ButtonTransparentStates.tertiary" @click="setGasPreset('medium')"/>
      <button-transparent :active="isDefaultSetting('gas', 'high')?true:null" text="high" :state="states.ButtonTransparentStates.tertiary" @click="setGasPreset('high')"/>
    </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import TextComp from './TextComp.vue';
import ButtonTransparent from './ButtonTransparent.vue';
export default {
  name: 'GasPresetSelector',
  components: {
    TextComp,ButtonTransparent
  },
  inject: injects.getInjects(),
  props: ["active","state","text","inputactive"],
  data() {
    return {
      states:states
    }
  },
  computed: {
    classState() {
      return this.state;
    },
    sliderParams() {
      return {
        
      }
    }
  }
}
</script>
<style scoped>
.gaspresetselector {
  position: relative;
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}
.gaspresetselector .leftcol {
  display: flex;
  justify-content: flex-start;
}
.gaspresetselector .rightcol {
  display: flex;
  justify-content: flex-start;
  align-self: right;
}
.gaspresetselector .rightcol span {
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  position: relative;
}
.gaspresetselector .leftcol.mobile {
}
.gaspresetselector .leftcol.desktop {
}
.gaspresetselector .leftcol.desktop {
}
.settings-inner-slider {
}
</style>
