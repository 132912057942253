<template>
 <div :connected="connected" :warning="warning" :state="state" class="popupview" :class="[classPlatform]">
  <div class="popupwindow" :class="[classPlatform,]" v-click-out-side="hideThis">
    <popup-header :warning="warning" />
    <div class="holder" :class="[classPlatform, helpActive?'help':'']">
        <popup-content-settings v-if="!isWarning&&!profileActive&&!helpActive" />
        <popup-content-warning v-if="isWarning" :item="item?item:null"/>
        <popup-content-profile v-if="profileActive" />
        <popup-help v-if="helpActive" />
    </div>
  </div>
 </div>
</template>
<script>
import PopupHeader from '../bricks/PopupHeader.vue'
import PopupContentSettings from '../bricks/PopupContentSettings.vue'
import PopupContentWarning from '../bricks/PopupContentWarning.vue'
import PopupContentProfile from '../bricks/PopupContentProfile.vue'
import PopupHelp from '../bricks/PopupHelp.vue'

/* eslint-disable */
import clickOutSide from "@mahdikhashan/vue3-click-outside";

const injects = require('../../modules/injects')
const states = require('../../modules/states')
export default {
  name: 'PopupView',
  directives: {
    clickOutSide,
  },
  components: {
    PopupHeader,PopupContentSettings,PopupContentWarning,PopupContentProfile,PopupHelp
  },
  inject: injects.getInjects(),
  props: ["state","connected","warning", "item"],
  data () {
    return {
      states: states,
      clicks: 0,
    }
  },
  computed: {
    isWarning() {
      return this.warning != null;
    },
    isConnected() {
      return this.connected != null;
    }
  },
  methods: {
    hideThis(e) {
      this.clicks++;
      if(this.clicks > 1) {
        this.clicks = 0;
        this.warning != null? this.cancelDeleteWallet() : ( this.hideHelpPopup(),this.hideProfilePopup(), this.hideSettingsPopup() );
      }
    }
  }
}
</script>
<style scoped>
.popupview {
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(3px) brightness(100%);
  backdrop-filter: blur(3px) brightness(100%);
  background-color: var(--bon-jour-2);
  z-index: 3;
}
.popupview.mobile {
  padding: 20px;
}
.popupview.desktop {
  padding: 80px;
}
.popupview .popupwindow {
  align-items: flex-start;
  background-color: var(--bon-jour-2);
  border: 1px solid;
  border-color: var(--sepia-skin-2);
  border-radius: 2px;
  box-shadow: 4px 4px 0px var(--sepia-skin-2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}
.popupview .popupwindow.desktop {
  max-width: 524px;
}
.popupview .popupwindow.mobile {
   max-width: 324px;
}
.popupview .popupwindow .holder {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 100%;
}
.popupview .popupwindow .holder.desktop {
  padding: 60px 30px;
}
.popupview .popupwindow .holder.mobile {
  padding: 40px 20px;
}
.popupview .popupwindow .holder.popupwindow.mobile {
  display: flex;
  justify-content: center;
  align-items: stretch;
  align-self: stretch;
  height: 100%;
}
.popupview .popupwindow .holder.help {
  padding: 0px;
}
</style>
