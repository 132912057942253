<template>
 <div :active="active" class="popupcontentprofile" :class="[classPlatform]">
  <div :class="['profile-diamond',classPlatform]">
    <icon-comp v-if="profileActive&&user&&connected&&user.tier&&user.tier.special"
    :class="['believers',classPlatform]"
    :state="states.IconTypes.believers"
    :src="'believers.svg'"/>
    <icon-comp v-if="user&&connected&&user.tier.expiration" :state="!isBasic()?states.IconTypes.diamond:states.IconTypes.diamondCrossed" />
  </div>
  <text-comp text="Profile" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
  <div v-if="user&&connected" class="content" :class="[classPlatform]">
    <div class="data">
      <text-comp text="cafe holdings" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="buttons" :class="[classPlatform]">
        <text-comp :text="cafe.BSC" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
        <text-comp :text="'BSC'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      </div>
    </div>
    <div class="data">
      <text-comp text="" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="buttons" :class="[classPlatform]">
        <text-comp :text="cafe.ETH" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
        <text-comp :text="'ETH'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      </div>
    </div>
    <div class="data">
      <text-comp text="tier" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="buttons" :class="[classPlatform]">
        <text-comp :text="user.tier.current" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
      </div>
    </div>
    <div v-if="!isBasic()&&user.tier.expiration" class="data">
      <text-comp text="valid till" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="buttons" :class="[classPlatform]">
        <text-comp :text="timeConverter(user.tier.expiration)" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
      </div>
    </div>
    <div v-if="eligibleData" class="data">
      <text-comp text="taxes to free upgrade" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="buttons" :class="[classPlatform]">
        <text-comp :text="`${parseFloat(eligibleData.usdToPro).toFixed(2)}$`" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
      </div>
    </div>
    <text-comp v-if="!isBasic()&&user.tier.expiration&&freeUpgradeAvailable" :text="`Free upgrade in next month!`" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
    <div v-if="user.tier.current!='BASIC'" class="data">
      <text-comp text="monthly subscription" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="buttons" :class="[classPlatform]">
        <button-transparent :active="user.tier.subscribe?true:null" :text="`active:${user.tier.subscribe?'on':'off'}`" :state="states.ButtonTransparentStates.tertiary" :key="'subscr'+usrReRenderer.toString()" @click="subscribe()"/>
      </div>
    </div>
    <text-comp text="referral" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
    <div class="data">
      <text-comp text="referral link" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="buttons" :class="[classPlatform]">
        <button-transparent :text="`copy`" :state="states.ButtonTransparentStates.tertiary" @click="copyRefLink()"/>
      </div>
    </div>
    <div v-if="referralData" class="data">
      <text-comp text="invited direct/indirect:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="buttons" :class="[classPlatform]">
        <text-comp :text="`${referralData.direct} / ${referralData.indirect}`" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
      </div>
    </div>
    <div v-if="referralData" class="data">
      <text-comp text="revenue to be paid:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="buttons" :class="[classPlatform]">
        <text-comp :text="`${getFloatWithDoublePrecision(parseFloat(
                (fromBNtoString(referralData.earnings.BNB.toString(),18)), true, true, 3))} BNB / ${getFloatWithDoublePrecision(parseFloat(
                (fromBNtoString(referralData.earnings.ETH.toString(),18)), true, true, 3))} ETH `" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
      </div>
    </div>
    <div v-if="referralData" class="data">
      <text-comp text="paid:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div class="buttons" :class="[classPlatform]">
        <text-comp :text="`${getFloatWithDoublePrecision(parseFloat(
                (fromBNtoString(referralData.paid.BNB.toString(),18)), true, true, 3))} BNB / ${getFloatWithDoublePrecision(parseFloat(
                (fromBNtoString(referralData.paid.ETH.toString(),18)), true, true, 3))} ETH `" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
      </div>
    </div>
    <text-comp v-if="isBasic()" :text="'Your current tier is basic.'" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
    <text-comp v-if="isBasic()" :text="getText()" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
    <text-comp v-if="isBasic()" :text="getText('price')" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
    <button-transparent v-if="isBasic()" text="UPGRADE NOW" :state="states.ButtonTransparentStates.primary" @click="showUpgrade()"/>
    <text-comp v-if="isBasic()&&this.showupgrade" :align="'left'" :text="getText('showupgrade')" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
    <text-comp v-if="isBasic()&&this.showupgrade" :text="getText('showupgrade2')" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
    <div v-if="isBasic()&&this.showupgrade" :class="['confirm-buttons', classPlatform]">
      <button-transparent text="yes" :state="states.ButtonTransparentStates.primary" @click="proceedUpgrade()"/>
      <button-transparent text="no" :state="states.ButtonTransparentStates.primary" @click="hideUpgrade()"/>
    </div>
  </div>
  <text-comp v-if="!connected" :text="getText('notconn')" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import ButtonTransparent from '../basic/ButtonTransparent.vue';
import ButtonFull from '../basic/ButtonFull.vue';
import TextComp from '../basic/TextComp.vue';
import IconComp from '../basic/IconComp.vue';
export default {
  name: 'PopupContentProfile',
  components: {
    ButtonTransparent,ButtonFull,TextComp, IconComp
  },
  inject: injects.getInjects(),
  props: ["active","reset"],
  data () {
    return {
      states:states,
      text: {
        upgrade: "Pro tier gives 50% taxes discount + free mev protection.",
        showupgrade: `Payment will be processed using your ${this.computedCurrentWalletLabel} via ordinary transfer of USD equivalent of ${this.computedWETH}`,
        showupgrade2: `Do you want to proceed?`,
        notconn: "YOU ARE NOT CONNECTED. CONNECT FIRST IF YOU WANT TO GET ACCESS TO THIS SECTION."
      },
      showupgrade: false,
    }
  },
  methods: {
  showUpgrade() {
    this.showupgrade = true;
  },
  getPriceOfUpgrade() {
    return parseInt(this.cafe.BSC+this.cafe.ETH) >= 10000? 25 : (this.user&&this.user.sponsor? 35 : 50);
  },
  hideUpgrade() {
    this.showupgrade = false
  },
  proceedUpgrade() {
    this.hideUpgrade();
    this.upgradeTier();
  },
  isBasic() {
    if(this.user) {
      if(this.user.tier.current == "BASIC") return true;
      else return false;
    } else return false;
  },
  getText(modifier) {
    if(this.user) {
      if(this.user.tier.current == "BASIC") {
        switch(modifier) {
          case "price":
            return `Price of upgrade: ${this.getPriceOfUpgrade()}$`;
          case "showupgrade":
            return this.text.showupgrade;
          case "showupgrade2":
            return this.text.showupgrade2;
          case "notconn":
            return this.text.notconn;
          default:
            return this.text.upgrade;
        }
      }
    }
    if(modifier == "notconn") return this.text.notconn;
    return "";
  },
  getUpgradePrice() {
    if(this.user) {
      return 25;
    }
    return 50;
  }
  }
}
</script>
<style scoped>
.confirm-buttons {
  align-self: stretch;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  position: relative;
}
.confirm-buttons.desktop{
  margin-left: 21.22%;
  margin-right: 21.22%;
}
.confirm-buttons.mobile{
  margin-left: 14.54%;
  margin-right: 14.54%;
}
.popupcontentprofile {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}
.profile-diamond {
  position: absolute;
  display: flex;
  flex-direction: row;
}
.profile-diamond.desktop {
  top:-2.5em;
  right:-1em;
}
.profile-diamond.mobile {
  top:-2em;
  right:-1em;
}
.popupcontentprofile.desktop {
  gap: 26px;
}
.popupcontentprofile.mobile {
  gap: 14px;
}
.popupcontentprofile .content{
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
}
.popupcontentprofile .content.desktop{
  gap: 16px;
}
.popupcontentprofile .content.mobile{
  gap: 8px;
}
.popupcontentprofile .content .data{
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.popupcontentprofile .content .data .buttons{
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.popupcontentprofile .content .data .buttons.mobile{
  gap: 14px;
}
.popupcontentprofile .content .data .buttons.desktop{
  gap: 25px;
}
</style>
