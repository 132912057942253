<template>
 <div :active="active" :state="state" :context="context" class="withdrawrow" :class="[classPlatform, classUse]">
  <div class="wrow">
    <text-comp :key="reRenderBridge" :text="'wallet:'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="textColor" />
    <text-comp
    :text="this.currentWallet?getWalletAddressOrTag(this.currentWallet.address):'No Wallet Selected'"
    :wrap="states.TextStatesStyles.BaseText.Wrap.truncated"
    :color="textColor"
    :align="'right'"/>
  </div>
  <div class="buttons">
    <div class="balance holderrow">
      <text-comp :key="reRenderBridge" :text="'balance:'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="textColor" />
      <div class="fitc">
        <text-comp :key="reRenderBridge" :text="this.cafe.BSCWALLET" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="textColor" />
        <text-comp :key="reRenderBridge" :text="' BSC / '" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="textColor" />
        <text-comp :key="reRenderBridge" :text="this.cafe.ETHWALLET" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="textColor" />
        <text-comp :key="reRenderBridge" :text="' ETH $CAFE'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="textColor" />
      </div>
    </div>
  </div>
  <div class="wpanel">
    <input-comp class="withdraw-value" :class="[classPlatform,inputClass]"
  type="text"
  :name="'baseWithdrawValue'"
  :placeh="'CAFE AMOUNT'"
  :value="baseWithdrawValue"
  @inputHandler="inputFieldHandler"/>
  <div class="icon-swap-holder prevent-select pointer" @click="switchBridgeDestination()" :class="[classPlatform]">
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" class="icon-button">
      <path style="fill:var(--jade)" d="M12.9841 4.99255C12.9841 4.44027 13.4318 3.99255 13.9841 3.99255C14.3415 3.99255 14.6551 4.18004 14.8319 4.46202L17.5195 7.14964C17.9101 7.54016 17.9101 8.17333 17.5195 8.56385C17.129 8.95438 16.4958 8.95438 16.1053 8.56385L14.9841 7.44263V14.9926C14.9841 15.5448 14.5364 15.9926 13.9841 15.9926C13.4318 15.9926 12.9841 15.5448 12.9841 14.9926V5.042C12.984 5.03288 12.984 5.02376 12.9841 5.01464V4.99255Z" fill="currentColor"></path>
      <path style="fill:var(--cedar-chest)" d="M11.0159 19.0074C11.0159 19.5597 10.5682 20.0074 10.0159 20.0074C9.6585 20.0074 9.3449 19.82 9.16807 19.538L6.48045 16.8504C6.08993 16.4598 6.08993 15.8267 6.48045 15.4361C6.87098 15.0456 7.50414 15.0456 7.89467 15.4361L9.01589 16.5574V9.00745C9.01589 8.45516 9.46361 8.00745 10.0159 8.00745C10.5682 8.00745 11.0159 8.45516 11.0159 9.00745V18.958C11.016 18.9671 11.016 18.9762 11.0159 18.9854V19.0074Z" fill="currentColor"></path>
    </svg>
  </div>
  <button-transparent
    :text="buttonText" :state="states.ButtonTransparentStates.primary"
    @click="bridge()"
    />
  </div>
  <div>
    <div class="togglesb">
      <button-transparent
          :key="'defcafeBSC'"
          :chainIcon="56"
          @click="search('0xdefCafE7eAC90d31BbBA841038DF365DE3c4e207', true, {chain: 56, address:'0xdefCafE7eAC90d31BbBA841038DF365DE3c4e207'})"
          :text="'CAFE'"
          :state="states.ButtonTransparentStates.favorites" />
          <button-transparent
          :key="'defcafeETH'"
          :chainIcon="1"
          @click="search('0xdefCafE7eAC90d31BbBA841038DF365DE3c4e207', true, {chain: 1, address:'0xdefCafE7eAC90d31BbBA841038DF365DE3c4e207'})"
          :text="'CAFE'"
          :state="states.ButtonTransparentStates.favorites" />
    </div>
  </div>
  </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import IconComp from '../basic/IconComp.vue';
import TextComp from '../basic/TextComp.vue'
import ButtonTransparent from '../basic/ButtonTransparent.vue';
import InputComp from '../basic/InputComp.vue';
import WalletsContextMenu from './WalletsContextMenu.vue';

export default {
  name: 'WithdrawRow',
  directives: {
    clickOutSide,
  },
  components: {
    IconComp,ButtonTransparent,TextComp,
    InputComp,
    WalletsContextMenu,
  },
  inject: injects.getInjects(),
  props: ["active","state", "context", "item", "index"],
  data () {
    return {
      states: states,
      use: false,
      clicks: 0
    }
  },
  computed: { 
    inputClass() {
      return this.classPlatform.desktop?states.TextStatesStyles.BaseText.desktop.default:states.TextStatesStyles.BaseText.mobile.default;
    },
    textColor() {
      return this.active!=null?states.TextStatesStyles.BaseText.Color.dark:states.TextStatesStyles.BaseText.Color.default
    },
    classUse() {
      return {
        pointer: this.use,
        "prevent-select": this.use
      }
    },
    buttonText() {
      return this.bridgeToETH?'TO ETH':'TO BSC';
    },
    isView() {
      return this.state == states.MainViewStatus.Content.WalletRow.View;
    },
    isSelectForImport() {
      return this.state == states.MainViewStatus.Content.WalletRow.SelectForImport;
    },
    isDeleted() {
      return this.state == states.MainViewStatus.Content.WalletRow.Deleted;
    },
    showContext() {
      return !this.isSelectForImport;
    },
    showUse() {
      return this.isView;
    }
  },
  methods: {
    hideThis(e) {
      this.clicks++;
      if(this.clicks > 1) {
        this.clicks = 0;
      }
    }
  }
}
</script>
<style scoped>
.withdraw-value {
  border: 1px solid var(--sepia-skin);
  border-radius: 2px;
  min-width: 20% !important;
  max-width: 30% !important;
}
.withdraw-value.desktop {
  padding: 0px 6px 0px;
}
.withdraw-value.mobile {
  padding: 0px 3px 0px;
}
.withdrawrow {
  width:100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}
.withdrawrow.desktop {
  gap: 12px;
}
.withdrawrow.mobile {
}
.buttons {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.buttons.desktop {
  gap: 8px;
  height: 21px;
}
.buttons.mobile {
  gap: 8px;
  height: 15px;
  margin-top: -1px;
}
.buttons .balance {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
}
.buttons .context-popup {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}
.wrow {
  display: flex;
  flex-direction: row;
}
.wline {
}
.wpanel {
  padding-top: 12px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:12px;
  position: relative;
}
.icon-swap-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--sepia-skin);
  transform: rotate(90deg);
}
.icon-swap-holder.mobile {
  width: 26px;
  min-width: 26px;
  height: 26px;
}
.icon-swap-holder.desktop {
  min-width: 36px;
  width: 36px;
  height: 36px;
}
.icon-swap-holder svg {
  width: 100%;
  height: 100%;
}
.holderrow {
  align-items: center !important;
  vertical-align: middle !important;
  padding: 0px 0px 0px 0px;
}
.fitc {
  width: fit-content;
  vertical-align: middle !important;
  display: flex;
  align-items: center !important;
  flex-direction: row;
}
.togglesb {
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
  padding-top: 12px;
}
</style>
