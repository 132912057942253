<template>
 <div :active="active" :state="state" class="order-holder" :class="[classPlatform]">
  <div class="orderrunning order">
    <div class="leftcol">
      <div class="leftcol-inner">
        <button-transparent
          :chainIcon="item.trade.settings.tech.chainID"
          @click="search(item.trade.settings.token.address, true, {chain: item.trade.settings.tech.chainID, address:item.trade.settings.token.address})"
          :text="toUpperCase(item.trade.settings.token.symbol)"
          :state="states.ButtonTransparentStates.tertiary" />
        <text-comp :text="`${toUpperCase(item.status)}${item.trade.out.split?' (SPLIT)':''}`"
                    :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                    :color="states.TextStatesStyles.BaseText.Color.dark" />
        <text-comp :text="item.trade.settings.trade.launchBuy?'ASS':'limit'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
        <text-comp :text="this.state" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color[(this.state == 'sell'?'red':'green')]" />

      </div>
      <div class="middlecol">
        <div class="midlecol-inner">
          <div v-if="item&&item.trade.user.wallet.address" class="data">
            <text-comp text="wallet:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value wall" :class="[classPlatform, classTablet]">
              <text-comp :text="getWalletAddressOrTag(item.trade.user.wallet.address)" :wrap="states.TextStatesStyles.BaseText.Wrap.truncated" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
          <div v-if="item.trade.settings.trade.launchBuy" class="data notification" :class="[classPlatform, classTablet]">
            <text-comp text="RETRY:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp :text="item.trade.settings.tech.disableRetry?'off':'on'" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :align="'left'" :color="states.TextStatesStyles.BaseText.Color.dark" />
          </div>
          <div v-if="item.trade.settings.trade.launchBuy" class="data notification" :class="[classPlatform, classTablet]">
            <text-comp text="PRICE:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp :text="`pumped < ${item.trade.settings.tech.maxPrice}%`" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :align="'left'" :color="states.TextStatesStyles.BaseText.Color.dark" />
          </div>
          <div v-if="item.trade.lbPriceDifference" class="data notification" :class="[classPlatform, classTablet]">
            <text-comp text="CURRENT PRICE:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp :text="`${item.trade.lbPriceDifference}% FROM LAUNCH`" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :align="'left'" :color="states.TextStatesStyles.BaseText.Color.dark" />
          </div>
          <div class="data">
            <text-comp text="amount:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <text-comp :text='getFloatWithDoublePrecision(parseFloat(
                (fromBNtoString(item.trade.settings.amounts.amount,item.trade.settings.trade.sell?item.trade.settings.token.decimals:18)), true, true))'
              :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
              <text-comp :text='`${!item.trade.settings.trade.sell?((isBNB(item.trade.settings.tech.chainID)?" BNB":" ETH")):""}`' :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
           <div v-if="item.trade.out.results" class="data">
            <text-comp text="filled:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <text-comp :text='getFloatWithDoublePrecision(parseFloat(
                (fromBNtoString(item.trade.out.results.trade.outputs.in,item.trade.settings.trade.sell?item.trade.settings.token.decimals:18)), true, true))'
              :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
              <text-comp :text='`${!item.trade.settings.trade.sell?((isBNB(item.trade.settings.tech.chainID)?" BNB":" ETH")):""}`' :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
            </div>
          </div>
          <div v-if="isBuy&&(item.trade.session||item.trade.out.results)||(item.trade.settings.trade.launchBuy&&(item.trade.settings.limits.sl.use||item.trade.settings.limits.tp.use))" class="complexdata" :class="classState">
            <div class="trigger">
              <text-comp :text="`${item.trade.settings.trade.launchBuy?'SELL:':'trigger:'}`" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
              <div class="databuy">
                <div v-if="!item.trade.settings.trade.launchBuy" class="data complex" :class="classPlatform">
                  <text-comp text="price <" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.green" />
                  <text-comp :text="(getFloatWithDoublePrecision(Number(item.trade.settings.limits.bp.amount), true))" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.green" />
                  <text-comp text="usd" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.green" />
                </div>
                <div v-if="(item.trade.settings.limits.tp.use||item.trade.settings.limits.sl.use)" class="data">
                  <text-comp text="buy" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.green" />
                  <text-comp text="and place" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
                  <text-comp text="sell" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.red" />
                  <text-comp text="order" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
                </div>
                <div class="data">
                  <text-comp v-if="item.trade.settings.limits.sl.use"
                    :text="`${item.trade.settings.limits.sl.use?(item.trade.settings.limits.sl.trail?'t/':''):''} LOSS > -${item.trade.settings.limits.sl.amount}%`" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.red" />
                  <text-comp v-if="item.trade.settings.limits.tp.use&&item.trade.settings.limits.sl.use" text="or" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
                  <text-comp v-if="item.trade.settings.limits.tp.use"
                    :text="`GAIN > +${item.trade.settings.limits.tp.amount}%`" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.green" />
              </div>
            </div>
            </div>
            <div v-if="!item.trade.settings.trade.launchBuy" class="data">
              <text-comp :text='`${!item.trade.out.results?"current ":""}price:`' :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
              <div class="value complex" :class="classPlatform">
                <text-comp
                  :text="item.trade.session?getFloatWithDoublePrecision(item.trade.session.USDPrice, true):'0'"
                  :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
                <text-comp text="USD" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
                <text-comp v-if="item.trade.session"
                  :text="(isPositiveValue(calculateBPPercentage(item.trade.settings.limits.bp.amount, item.trade.session.USDPrice))?'+':'')+calculateBPPercentage(item.trade.settings.limits.bp.amount, item.trade.session.USDPrice)+'%'"
                  :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                  :color="isPositiveValue(calculateBPPercentage(item.trade.settings.limits.bp.amount, item.trade.session.USDPrice))?
                                          states.TextStatesStyles.BaseText.Color.red:
                                          states.TextStatesStyles.BaseText.Color.green" />
              </div>
            </div>
          </div>
          <div v-if="isSell&&(item.trade.session||item.trade.out.results)&&!item.trade.settings.trade.launchBuy" class="complexdata" :class="classState">
            <div class="trigger">
              <text-comp text="trigger:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
              <div class="databuy">
                <div class="data">
                  <text-comp v-if="item.trade.settings.limits.sl.use"
                  :text="`${item.trade.settings.limits.sl.use?(item.trade.settings.limits.sl.trail?'t/':''):''}loss > -${item.trade.settings.limits.sl.amount}%`" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.red" />
                  <text-comp v-if="item.trade.settings.limits.tp.use&&item.trade.settings.limits.sl.use" text="or" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
                  <text-comp v-if="item.trade.settings.limits.tp.use"
                  :text="`gain > +${item.trade.settings.limits.tp.amount}%`" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.green" />
                </div>
            </div>
            </div>
            <div class="data">
              <text-comp
              :text="`${!item.trade.out.results?'current ':''}${isPositiveValue(item.trade.session.gainedTotal)?'GAIN:':'LOSS:'}`"
              :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
              <div class="value">
                <text-comp
                :text="`${isPositiveValue(item.trade.session.gainedTotal)?'+':''}${parseFloat(item.trade.session.gainedTotal).toFixed(3)}%`" :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="isPositiveValue(item.trade.session.gainedTotal)?states.TextStatesStyles.BaseText.Color.green:states.TextStatesStyles.BaseText.Color.red" />
              </div>
            </div>
            <div v-if="item.trade.settings.trade.sell&&item.trade.settings.limits.sl.use&&item.trade.settings.limits.sl.trail" class="data">
              <text-comp text="trailing loss:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
              <div class="value">
                <text-comp
                :text="`${parseFloat(item.trade.session.currentLoss).toFixed(3)}%`" :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="isPositiveValue(item.trade.session.currentLoss)?states.TextStatesStyles.BaseText.Color.green:states.TextStatesStyles.BaseText.Color.red" />
              </div>
            </div>
          </div>
          <div v-if="(item.trade.session||item.trade.out.results)&&!item.trade.settings.trade.launchBuy" class="data">
            <text-comp :text='`${!item.trade.out.results?"current ":""}value:`' :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
              <div class="value complex" :class="classPlatform">
                <text-comp
                  :text="`${item.trade.session?
                (!item.trade.out.results?((item.trade.settings.trade.sell?getFloatWithDoublePrecision(parseFloat(
                (fromBNtoString(item.trade.session.currentPrice.toString(),18)))):
                getFloatWithDoublePrecision(item.trade.session.BPAmtOut,false, true))):
                getFloatWithDoublePrecision(parseFloat(
                (fromBNtoString(item.trade.out.results.trade.outputs.out,!item.trade.settings.trade.sell?item.trade.settings.token.decimals:18))), false, true)):'0'}`"
                :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="item.trade.settings.trade.sell?
                (isPositiveValue(item.trade.session?item.trade.session.gainedTotal:1)?
                states.TextStatesStyles.BaseText.Color.green
                :states.TextStatesStyles.BaseText.Color.red)
                :states.TextStatesStyles.BaseText.Color.green" />
                <text-comp :text="`${item.trade.settings.trade.sell?(isBNB(item.trade.settings.tech.chainID)?'BNB':'ETH'):item.trade.settings.token.symbol}`"
                :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="item.trade.settings.trade.sell?
                (isPositiveValue(item.trade.session?item.trade.session.gainedTotal:1)?
                states.TextStatesStyles.BaseText.Color.green
                :states.TextStatesStyles.BaseText.Color.red)
                :states.TextStatesStyles.BaseText.Color.green" />
              </div>
          </div>
          <div v-if="(item.trade.session||item.trade.out.results)&&!item.trade.settings.trade.launchBuy" class="data">
            <text-comp text="slippage:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
                <button-transparent v-if="(showSlippageTuneButton == 2)||(showSlippageTuneButton == 3)" :text="'-'" :state="states.ButtonTransparentStates.tertiary"
                            @click="setLimitOrderSlippage(item.uuid, false)"/>
                <text-comp :text="`${item.trade.out.results?item.trade.out.results.settings.tech.executed.adjustedSlippage:item.trade.session.slippage}`"
                :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="states.TextStatesStyles.BaseText.Color.dark"
                />
                <button-transparent v-if="(showSlippageTuneButton == 1)||(showSlippageTuneButton == 2)" :text="'+'" :state="states.ButtonTransparentStates.tertiary"
                            @click="setLimitOrderSlippage(item.uuid, true)"/>
                <text-comp v-if="item.trade.out.results" :text="`(tax ${parseInt(item.trade.out.results.settings.tech.executed.tax)} + ${parseInt(item.trade.out.results.settings.tech.initial.slippage)})`"
                :wrap="states.TextStatesStyles.BaseText.Wrap.full"
                :color="states.TextStatesStyles.BaseText.Color.dark"
                />
            </div>
          </div>
          <div v-if="(item.trade.session||item.trade.out.results)&&!item.trade.settings.trade.launchBuy" class="data">
            <text-comp text="gas preset:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
                <button-transparent :active="this.isCurrentGasPreset('low')" text="low" :state="states.ButtonTransparentStates.tertiary" @click="setLimitOrderGas(item.uuid, 'low')"/>
                <button-transparent :active="this.isCurrentGasPreset('medium')" text="medium" :state="states.ButtonTransparentStates.tertiary" @click="setLimitOrderGas(item.uuid, 'medium')"/>
                <button-transparent :active="this.isCurrentGasPreset('high')" text="high" :state="states.ButtonTransparentStates.tertiary" @click="setLimitOrderGas(item.uuid, 'high')"/>
            <div class="value">

            </div>
          </div>
          <div v-if="(item.trade.session)&&!isBNB(item.trade.settings.tech.chainID)&&computeShowLimitButton(item.uuid) &&isMevProtection(item.uuid)" class="data">
            <text-comp text="RETRY:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
                <button-transparent
                :active="!this.isDisableRetry()?true:null"
                :text="`${!this.isDisableRetry()?'on':'off'}`"
                :state="states.ButtonTransparentStates.tertiary" :color="states.TextStatesStyles.BaseText.Color.default"
                @click="setLimitOrderRetry(item.uuid, !this.isDisableRetry())"/>
            <div class="value">
            </div>
          </div>
          <div v-show="item.trade.notification" class="data notification" :class="[classPlatform, classTablet]">
            <text-comp text="STATUS:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp :text="item.trade.notification" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :align="'left'" :color="states.TextStatesStyles.BaseText.Color.dark" />
          </div>
          <div v-show="item.trade.errors" class="data notification" :class="[classPlatform, classTablet]">
            <text-comp text="TRACES:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp :text="item.trade.errors" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :align="'left'" :color="states.TextStatesStyles.BaseText.Color.dark" />
          </div>
          <div v-if="item.trade.session||item.trade.out.results" class="data">
            <text-comp text="tx:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
            <div class="value">
              <a class="prevent-select pointer"
              :href="item.tx||item.trade.out.results?(isBNB(item.trade.settings.tech.chainID)?'https://bscscan.com/tx/':item.trade.settings.tech.chainID == 8453?'https://basescan.org/tx/':`https://${item.trade.settings.tech.chainID != 1?'sepolia.':''}etherscan.io/tx/`) + (item.trade.out.results?item.trade.out.results.trade.txHash:item.tx):'#'"
              :target="(item.tx||item.trade.out.results)?'blank':''" >
                <text-comp
                :text="item.tx||item.trade.out.results?'<TX HASH>':'N/A'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.dark" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="lastcol">
      <div class="row1">
        <button-transparent v-if="computeShowExecuteButton(item.uuid)" text="execute" :state="states.ButtonTransparentStates.tertiary"
                            @click="execute(item.uuid)"/>
      </div>
      <div class="row2" :class="[classPlatform]">
        <button-transparent
        v-if="(item.trade.session)&&!isBNB(item.trade.settings.tech.chainID)&&computeShowLimitButton(item.uuid)"
        :active="isMevProtection(item.uuid)?true:null"
        :text="`mev:${isMevProtection(item.uuid)?'on':'off'}`"
        :state="states.ButtonTransparentStates.tertiary"
        @click="setLimitOrderProtectons(item.uuid, 'setmev', !item.trade.session.protections.mev)" />
        <button-transparent
        v-if="computeShowLimitButton(item.uuid)"
        :text="toUpperCase(getOrderAction(item.uuid))" :state="states.ButtonTransparentStates.tertiary"
        @click="autoOrderAction(item.uuid)"/>
      </div>
    </div>
  </div>
 </div>
</template>
<script>
/* eslint-disable */
/*      */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import ButtonTransparent from './ButtonTransparent.vue';
import TextComp from './TextComp.vue';
import IconComp from './IconComp.vue';

export default {
  name: 'OrderRunning',
  components: {
    ButtonTransparent,TextComp,IconComp
  },
  inject: injects.getInjects(),
  props: ["active","state", "item", "index"],
  data () {
    return {
      states:states    }
  },
  computed: {
    classState() {
      return this.state;
    },
    showSlippageTuneButton() {
      return this.item?(
              this.item.status != 'running'?0:
              (this.item.trade.session.slippage > 1 && this.item.trade.session.slippage < 99)?2:
              (this.item.trade.session.slippage == 1?1:3)
              ):0;
    },
    showGasButton() {
      return this.item?(
              this.item.status != 'running'?0:
              (this.item.trade.session.gas > 3 && this.item.trade.session.gas < 150)?2:
              (this.item.trade.session.gas == 3?1:3)
              ):0;
    },
    isBuy() {
      return (this.state == states.OrderRunning.LimitType.buy);
    },
    isSell() {
      return (this.state == states.OrderRunning.LimitType.sell);
    }
  },
  methods: {
    isDisableRetry() {
      return this.item&&this.item.trade.session?this.item.trade.session.disableRetry:null;
    },
    isCurrentGasPreset(preset){
       return this.item&&this.item.trade.session&&this.item.trade.session.gasPreset === preset?true:null;
    },
  },
}
</script>
<style scoped>
.order-holder.desktop {
  padding-bottom: 8px;
}
.order-holder.mobile {
  padding-bottom: 8px;
}
.orderrunning {
  border: 1px solid;
  border-color: var(--sepia-skin-2);
  border-radius: 2px;
  padding: 4px;
  flex: 1;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}
.orderrunning.order {
  align-items: flex-start;
  display: flex;
  position: relative;
}
.leftcol {
  display: flex;
  position: relative;
  width: fit-content;
  align-items: flex-start;
  flex-direction: column;
}
.leftcol-inner {
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
}
.middlecol {
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
  align-items: flex-start;
}
.middlecol-inner{
  align-items: flex-start;
  justify-content: center;
  display: flex;
  position: relative;
  width: fit-content;
}
.lastcol {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: fit-content;
  padding: 4px;
  top: 0;
  right: 0;
  height: 100%;
}
.row1 {
  align-items: center;
  display: flex;
  gap: 2px;
  justify-content: flex-end;
  position: relative;
  width: fit-content;
}
.row2 {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
  width: fit-content;
}
.row2.mobile {
  gap: 2px;
}
.data {
  display: flex;
  position: relative;
  width: fit-content;
  align-items: center;
}
.databuy {
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.value {
  align-items: center;
  display: flex;
  position: relative;
  width: 60%;
}
.complexdata {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
  flex-direction: column;
}
.trigger {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
}
.complex {
  align-items: flex-start;
  justify-content: flex-start;
}
.value.wall {
  width: fit-content;
  max-width: 50vw;
}
.value.wall.desktop,
.value.wall.tablet {
  max-width: 17vw;
}
.data.notification{
  width: fit-content;
  display: flex;
  position: relative;
  max-width: 65vw;
  width: 100%;
  align-items: flex-start;
}
.data.notification.desktop,
.data.notification.tablet {
  max-width: 25vw;
}
</style>
