<template>
 <div :active="active" :flexible="flexible" :state="state" :light="light" class="prevent-select pointer iconcomp" :class="[classPlatform,classStates, classFlexible, classActive, classHollowWithHover, classHollow]"
  @mouseover="hover = true"
  @mouseleave="hover = false"
  @click="href != null? openInNewTab(href): ()=>''"
  :key="styleChanged">
    <img v-bind:src="imgPath"/>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')
export default {
  name: 'IconComp',
  components: {
  },
  inject: injects.getInjects(),
  props: ["text", "active", "state", "light", "flexible","href", "src"],
  data() {
    return {
    states: states,
    hover: false
    }
  },
  computed: {
    classFlexible() {
      return {flexible: (this.flexible != null) }
    },
    classStates() {
      return  states.getIconTypeClass(this.state);
    },
    imgPath() {    
      return "./img/icons/"+states.getIconPath(this.state,this.hover||this.active != null?
                                                          states.IconStates.hover:
                                                          states.IconStates.default,
                                                          this.classPlatform, 
                                                          this.light != null && !this.hover && this.active == null?true:false,
                                                          this.src?this.src:false)+
                                                          (this.src?'':(!(states.IconTypes.Chains[this.state])?".png":".svg"));
    },
    classActive() {
      return this.hover||this.active?'act-as-active':'';
    },
    classHollow() {
      return this.state == states.IconTypes.starfilled?'hollow':'';
    },
    classHollowWithHover() {
      return this.state == states.IconTypes.close?'hollow-with-hover':'';
    }
  },
  methods: {
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer');
    },
  },
}
</script>
<style scoped>
.iconcomp {
  display: flex;
  align-content: center;
  filter: var(--filter);
  webkitFilter: var(--filter);
  -webkit-filter: var(--filter);
}
.iconcomp.telegram.desktop {
  width: 32px;
  height: 32px;
}
.iconcomp.telegram.mobile {
  width: 24px;
  height: 24px;
}
.iconcomp.believers.desktop {
  width: 34px;
  height: 32px;
}
.iconcomp.believers.mobile {
  width: 26px;
  height: 24px;
}
.iconcomp.diamond.desktop {
  width: 32px;
  height: 32px;
}
.iconcomp.diamond.mobile {
  width: 25px;
  height: 24px;
}
.iconcomp.diamondCrossed.desktop {
  width: 32px;
  height: 32px;
}
.iconcomp.diamondCrossed.mobile {
  width: 25px;
  height: 24px;
}
.iconcomp.profile.desktop {
  width: 27px;
  height: 32px;
}
.iconcomp.profile.mobile {
  width: 20px;
  height: 24px;
}
.iconcomp.questionmark.desktop {
  width: 27px;
  height: 32px;
}
.iconcomp.questionmark.mobile {
  width: 20px;
  height: 24px;
}
.iconcomp.settings.desktop {
  width: 32px;
  height: 32px;
}
.iconcomp.settings.mobile {
  width: 24px;
  height: 24px;
}
.iconcomp.star.desktop {
  width: 16px;
  height: 16px;
}
.iconcomp.star.mobile {
  width: 16px;
  height: 16px;
}
.iconcomp.starfilled.desktop {
  width: 16px;
  height: 16px;
}
.iconcomp.starfilled.mobile {
  width: 16px;
  height: 16px;
}
.iconcomp.trash.desktop {
  width: 16px;
  height: 16px;
}
.iconcomp.trash.mobile {
  width: 12px;
  height: 12px;
}
.iconcomp.search.desktop {
  width: 16px;
  height: 16px;
}
.iconcomp.search.mobile {
  width: 16px;
  height: 100%;
}
.iconcomp.context.desktop {
  width: 18px;
  height: 14px;
}
.iconcomp.context.mobile {
  width: 16px;
  height: 11px;
}
.iconcomp.eraseexpand.desktop {
  width: 10px;
  height: 16px;
}
.iconcomp.eraseexpand.mobile {
  width: 8px;
  height: 12px;
}
.iconcomp.close.desktop {
  width: 16px;
  height: 16px;
}
.iconcomp.close.mobile {
  width: 16px;
  height: 16px;
}
.iconcomp img {
  width: 100%;
  height: 100%;
  position: relative;
}
.iconcomp.flexible {
  height: 100% !important;
}
.iconcomp:hover {

}
.hollow-with-hover {
  filter: var(--filter-hollow);
  webkitFilter: var(--filter-hollow);
  -webkit-filter: var(--filter-hollow);
}
.act-as-active {
  filter: var(--filter-active);
  webkitFilter: var(--filter-active);
  -webkit-filter: var(--filter-active);
}
.hollow {
  filter: var(--filter-hollow);
  webkitFilter: var(--filter-hollow);
  -webkit-filter: var(--filter-hollow);
}
</style>
