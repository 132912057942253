<template>
 <div :active="active" class="buypanelheader" :class="[classPlatform]">
  <window-bar absolute />
  <div class="wallet" :class="[classPlatform]">
    <text-comp :key="'wb'+reRender"
    :text="this.currentWallet?(this.currentWallet.balance != undefined ? this.currentWallet.balance : ''): ''" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.light" />
    <text-comp :key="'w'+reRender"
    :text="computedCurrentWalletLabel" :wrap="states.TextStatesStyles.BaseText.Wrap.truncated" :color="states.TextStatesStyles.BaseText.Color.light" />
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')
//  <chain-switch :chain="isBNB(this.chain.ID)?56:1" />
import TextComp from '../basic/TextComp.vue';
import ChainSwitch from '../basic/ChainSwitch.vue';
import WindowBar from '../basic/WindowBar.vue';
export default {
  name: 'BuyPanelHeader',
  components: {
    WindowBar,TextComp,ChainSwitch
  },
  inject: injects.getInjects(),
  props: ["active"],
  data () {
    return {
      states:states    
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.buypanelheader {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow-y: hidden;
}
.buypanelheader.desktop {
  height: 28px;
  padding-bottom: 1px;
}
.buypanelheader.mobile {
  height: 20px;
  padding-bottom: 4px;
}
.buypanelheader .wallet {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  position: relative;
}
.buypanelheader .wallet.desktop {
  padding: 0px 6px 2px;
}
.buypanelheader .wallet.mobile {
  padding: 0px 4px;
}
</style>
