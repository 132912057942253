<template>
  <div :active="active" class="prevent-select pointer buttonfull" :class="[classPlatform,classProps]" :text="text"
  @mouseover="hover = true" @mouseleave="hover = false" >
    <div class="button" :class="[classPlatform,classProps,classTextState]">{{text?text:"button"}}</div>
  </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')
export default {
  name: 'ButtonFull',
  components: {
  },
  inject: injects.getInjects(),
  props: ["text", "active"],
  data() {
    return {
      hover: false
    }
  },
  computed: {
    classTextState() {
      return states.TextStatesStyles.ButtonFull.getClass(this.classPlatform,this.hover,this.active != null?true:false);
    },
    classProps() {
      return {
        active: this.active != null
      }
    }
  }
}
</script>
<style scoped>
.buttonfull {
  align-items: center;
  background-color: transparent;
  border-color: transparent;
  border-left-style: solid;
  border-radius: 2px 2px 0px 0px;
  border-right-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: fit-content;
}
.buttonfull.mobile {
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
  height: 20px;
  padding: 0px 6px 0px;
}
.buttonfull.desktop {
  border-left-width: 2px;
  border-right-width: 2px;
  border-top-width: 2px;
  border-bottom-width: 2px;
  height: 28px;
  padding: 0px 10px 0px;
}

.buttonfull.absolute {
  position: absolute;
}
.button {
  letter-spacing: 0;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
.buttonfull:hover {
  background-color: var(--bon-jour);
}
.buttonfull.active {
  background-color: var(--bon-jour);
}
</style>