<template>
  <div :active="active" :inputactive="inputactive" :text="text" :state="state" class="settingsslider" :class="[classPlatform]">
    <div class="leftcol" :class="classPlatform">
      <text-comp :text="this.text == 'slippage'?'slip':this.text == 'gas'?'max price:':this.text + ':'" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <button-transparent v-show="this.text != 'gas'" :active="isDefaultSetting(this.text)?true:null" text="low" :state="states.ButtonTransparentStates.tertiary" @click="setDefaultSetting(this.text)"/>
    </div>
    <slider-comp :inputactive="inputactive" class="settings-inner-slider" :class="[classPlatform]" :state="states.Slider.Type.settings"
     :gallery="this.text == 'slippage'?'slip':this.text" :labelval="this.text == 'slippage'?'settingsSlippage':'settingsGas'"/>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import TextComp from './TextComp.vue';
import ButtonTransparent from './ButtonTransparent.vue';
import SliderComp from './SliderComp.vue';
export default {
  name: 'SettingsSlider',
  components: {
    TextComp,SliderComp,ButtonTransparent
  },
  inject: injects.getInjects(),
  props: ["active","state","text","inputactive"],
  data() {
    return {
      states:states
    }
  },
  computed: {
    classState() {
      return this.state;
    },
    sliderParams() {
      return {
        
      }
    }
  }
}
</script>
<style scoped>
.settingsslider {
  position: relative;
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}
.settingsslider .leftcol {
  display: flex;
  justify-content: space-between;
}
.settingsslider .leftcol.mobile {
}
.settingsslider .leftcol.desktop {
}
.settingsslider .leftcol.desktop {
}
.settings-inner-slider {
}
</style>
