<template>
 <div :active="active" :item="item" class="popupcontentwarning" :class="[classPlatform]">
  <text-comp text="confirm action:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
  <text-comp :text="`You are about to delete your wallet${this.item?' '+getWalletAddressOrTag(item.address):''}. This action is irreversible. Are you sure you want to proceed?`" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.dark" />
  <div class="content" :class="[classPlatform]">
    <div class="data" :class="[classPlatform]">
        <button-transparent text="yes" :state="states.ButtonTransparentStates.primary" @click="deleteWallet()"/>
        <button-transparent text="no" :state="states.ButtonTransparentStates.primary" @click="cancelDeleteWallet()"/>
    </div>
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import ButtonTransparent from '../basic/ButtonTransparent.vue';
import ButtonFull from '../basic/ButtonFull.vue';
import TextComp from '../basic/TextComp.vue';
export default {
  name: 'PopupContentWarning',
  components: {
    ButtonTransparent,ButtonFull,TextComp
  },
  inject: injects.getInjects(),
  props: ["active", "item"],
  data () {
    return {
      states:states    
    }
  },
  methods: {
  },
}
</script>
<style scoped>
.popupcontentwarning {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.popupcontentwarning.desktop {
  gap: 26px;
}
.popupcontentwarning.mobile {
  gap: 14px;
}
.popupcontentwarning .content{
  align-self: stretch;
  display: flex;
  position: relative;
}
.popupcontentwarning .content.desktop{
}
.popupcontentwarning .content.mobile{
}
.popupcontentwarning .content .data{
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  position: relative;
}
.popupcontentwarning .content .data.desktop{
  margin-left: 21.22%;
  margin-right: 21.22%;
}
.popupcontentwarning .content .data.mobile{
  margin-left: 14.54%;
  margin-right: 14.54%;
}
</style>
