<template>
 <div :active="active" :state="state" :context="context" class="withdrawentry" :class="[classPlatform, classUse]">
 <div class='flw'>
  <text-comp
    :text="index+1+'.'"
    :wrap="states.TextStatesStyles.BaseText.Wrap.truncated"
    :color="textColor"
    :align="'left'"/>
    <text-comp
    :text="getFloatWithDoublePrecision(parseFloat(
                (fromBNtoString(item.amount,9)), true, true))"
    :wrap="states.TextStatesStyles.BaseText.Wrap.truncated"
    :color="textColor"
    :align="'left'"/>
    <text-comp
    :text="'CAFE'"
    :wrap="states.TextStatesStyles.BaseText.Wrap.truncated"
    :color="textColor"
    :align="'left'"/>
    <text-comp
    :text="parseInt(item.chainID)==1?'TO BSC':parseInt(item.chainID)==56?'TO ETH':'TO UNKNOWN CHAIN'"
    :wrap="states.TextStatesStyles.BaseText.Wrap.truncated"
    :color="textColor"
    :align="'left'"/>
 </div>
  <div class="buttons">
    <text-comp v-if="!item.completed&&date > 0"
    :text="'(' + date + 's)'"
    :key="item.log.hash + counter"
    :wrap="states.TextStatesStyles.BaseText.Wrap.truncated"
    :color="textColor"
    :align="'right'"/>
    <button-transparent
    :text="!item.completed?'withdraw':item.completed?'finished':'check status'" :state="states.ButtonTransparentStates.tertiary"
    :active="item.completed?true:null"
    @click="!item.completed?nextWithdrawStage(index):''"
    />
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import IconComp from '../basic/IconComp.vue';
import TextComp from '../basic/TextComp.vue'
import ButtonTransparent from '../basic/ButtonTransparent.vue';
import InputComp from '../basic/InputComp.vue';
import WalletsContextMenu from './WalletsContextMenu.vue';

export default {
  name: 'WithdrawEntry',
  directives: {
    clickOutSide,
  },
  components: {
    IconComp,ButtonTransparent,TextComp,
    InputComp,
    WalletsContextMenu,
  },
  inject: injects.getInjects(),
  props: ["active","state", "context", "item", "index"],
  data () {
    return {
      states: states,
      use: false,
      clicks: 0,
      currentTimestamp: Date.now()
    }
  },
  created() {
    setInterval(() => {
      this.currentTimestamp = Date.now();
    }, 1000);
  },
  computed: { 
    inputClass() {
      return this.classPlatform.desktop?states.TextStatesStyles.BaseText.desktop.default:states.TextStatesStyles.BaseText.mobile.default;
    },
    textColor() {
      return this.active!=null?states.TextStatesStyles.BaseText.Color.dark:states.TextStatesStyles.BaseText.Color.default
    },
    classUse() {
      return {
        pointer: this.use,
        "prevent-select": this.use
      }
    },
    isView() {
      return this.state == states.MainViewStatus.Content.WalletRow.View;
    },
    isSelectForImport() {
      return this.state == states.MainViewStatus.Content.WalletRow.SelectForImport;
    },
    isDeleted() {
      return this.state == states.MainViewStatus.Content.WalletRow.Deleted;
    },
    showContext() {
      return !this.isSelectForImport;
    },
    showUse() {
      return this.isView;
    },
    date() {
      const diff = (this.item.confirmation - this.currentTimestamp)/1000;
      return (Math.round(diff) > 0 ? Math.round(diff) : 0);
    }
  },
  methods: {
    hideThis(e) {
      this.clicks++;
      if(this.clicks > 1) {
        this.clicks = 0;
        this.hideRename(e);
      }
    }
  }
}
</script>
<style scoped>
.flw {
  display: flex;
}
.withdraw-value {
  border: 1px solid var(--sepia-skin);
  border-radius: 2px;
  min-width: 20% !important;
  max-width: 30% !important;
}
.withdraw-value.desktop {
  padding: 0px 6px 0px;
}
.withdraw-value.mobile {
  padding: 0px 3px 0px;
}
.withdrawentry {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.withdrawentry.desktop {
  height: 27px;
  gap: 12px;
}
.withdrawentry.mobile {
  height: 20px;
}
.withdrawentry .buttons {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.withdrawentry .buttons.desktop {
  gap: 8px;
  height: 21px;
}
.withdrawentry .buttons.mobile {
  gap: 8px;
  height: 15px;
  margin-top: -1px;
}
.withdrawentry .buttons .balance {
  align-items: flex-start;
  display: flex;
  position: relative;
  width: fit-content;
}
.withdrawentry .buttons .context-popup {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}
</style>
