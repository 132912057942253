export const CHAIN_ID_TO_MULTICALL_ADDRESS: { [key: number]: string } = {
  1: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
  3: '0xF24b01476a55d635118ca848fbc7Dab69d403be3',
  4: '0x42ad527de7d4e9d9d011ac45b31d8551f8fe9821',
  5: '0x77dca2c955b15e9de4dbbcf1246b4b85b651e50e',
  42: '0x2cc8688c5f75e365aaeeb4ea8d6a480405a48d2a',
  56: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb',
  100: '0xb5b692a88bdfc81ca69dcb1d924f59f0413a602a',
  137: '0xc4f1501f337079077842343Ce02665D8960150B0',
  8453: '0xa229e9Dbe1e13576da18DFFeB21B1551A1984F7f',
  80001: '0x5a0439824F4c0275faa88F2a7C5037F9833E29f1',
  42161: '0x813715eF627B01f4931d8C6F8D2459F26E19137E',
  421611: '0xc72e0331e2e7aeb30aa7d3d250d245ebf2f27037',
  11155111: '0x5852b3590628C6F517888B3C1Da94000bB33A20a'
};
