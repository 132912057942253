<template>
 <div :active="active" class="snipeviewcontentheader" :class="[classPlatform]">
  <div class="innerholder" :class="[classPlatform]">
    <div class="header" :class="[classPlatform]">
      <button-transparent
      :active="!showActiveSnipes?null:true"
      text="Active"
      :state="states.ButtonTransparentStates.tertiary"
      @click="changeSnipeView()" />
      <button-transparent
      :active="!showActiveSnipes?true:null"
      text="Completed"
      :state="states.ButtonTransparentStates.tertiary"
      @click="changeSnipeView()" />
    </div>
  </div>
 </div>
</template>
<script>
/* eslint-disable */

const injects = require('../../modules/injects')
const states = require('../../modules/states')

import IconComp from '../basic/IconComp.vue';
import TextComp from '../basic/TextComp.vue'
import ButtonTransparent from '../basic/ButtonTransparent.vue';
import InputComp from '../basic/InputComp.vue';

export default {
  name: 'SnipeViewContentHeader',
  components: {
    IconComp,ButtonTransparent,TextComp,
    InputComp,
  },
  inject: injects.getInjects(),
  props: ["active"],
  data () {
    return {
      states: states,
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style scoped>
.snipeviewcontentheader {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}
.snipeviewcontentheader .innerholder {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
  position: relative;
}
.snipeviewcontentheader .innerholder.mobile {
  gap: 10px;
}
.snipeviewcontentheader .innerholder.desktop {
  gap: 24px;
}
.snipeviewcontentheader .innerholder .header {
  align-items: center;
  align-self: stretch;
  gap: 17px;
  justify-content: flex-end;
  padding: 0px 0px 0px 0px;
  display: flex;
  position: relative;
}
.snipeviewcontentheader .innerholder .header.mobile {
  align-items: flex-start
}
.snipeviewcontentheader .innerholder .content {
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-self: stretch;
  display: flex;
  position: relative;
}
.snipeviewcontentheader .innerholder .content .inner-content {
  align-items: center;
  gap: 6px;
  align-self: stretch;
  display: flex;
  position: relative;
}
.snipeviewcontentheader .innerholder .content .inner-content.mobile {
  padding: 0px 3px;
}
.snipeviewcontentheader .innerholder .content .inner-content.desktop {
  padding: 0px 6px;
}
.snipeviewcontentheader .innerholder .content .keyinput {
  border: 1px solid var(--sepia-skin);
  border-radius: 2px;
  max-width: 50%;
}
</style>
