/* eslint-disable */
const chains = require('./chains');
module.exports = {
    provider: null,
    swap: null,
    isV3: false,
    quoter: null,
    initialize: function(quoter, prov, swap) {
      this.quoter = quoter,
      this.provider = prov;
      this.swap = swap;
      this.isV3 = this.swap.name == chains.ETH.swaps.UniswapV3.name? true:false;
    },
    isV3: function(swap) {
      return swap.name == chains.ETH.swaps.UniswapV3.name? true:false;
    },
    getAmountsOut: async function(amountsIn, packedData, reversed = false) {
      let res = 0;
      if(!packedData) throw new Error('Packed path data is necessary!');
      if(!this.isV3) {
        await this.quoter.methods.getAmountsOut(amountsIn, !reversed?packedData.path:packedData.reversedPath).call({from: "0xdefcafe7eac90d31bbba841038df365de3c4e207"}, function(error, result){ 
          if(result) {                
            res = result[packedData.path.length - 1];
          }
        });
        return res;
      } else {
        await this.quoter.methods.quoteExactInput(!reversed?packedData.path:packedData.reversedPath,amountsIn).call({from: "0xdefcafe7eac90d31bbba841038df365de3c4e207"}, function(error, result){ 
          if(result) {
            res = result;
          }  
      });
      } 
      if(res == 0 || !res) res = false;
      if(!res) throw new Error('Cannot get amounts out!');
      return res; 
  },
  getAmountsIn: async function(amountsOut, packedData, reversed = false) {
    let res = 0;
    if(!packedData) throw new Error('Packed path data is necessary!');
    if(!this.isV3) {
      await this.quoter.methods.getAmountsIn(amountsOut, !reversed?packedData.path:packedData.reversedPath).call({from: "0xdefcafe7eac90d31bbba841038df365de3c4e207"}, function(error, result){ 
        if(result) {                
          res = result[0];
        }
      });
      return res;
    } else {
      await this.quoter.methods.quoteExactInput(!reversed?packedData.reversedPath:packedData.path,amountsOut).call({from: "0xdefcafe7eac90d31bbba841038df365de3c4e207"}, function(error, result){ 
        if(result) {
          res = result;
        }
    });
    } 
    if(res == 0 || !res) res = false;
    if(!res) throw new Error('Cannot get amounts in!');
    return res; 
},
getAmountsOutMethod: function(amountsIn, packedPath) {
  if(!this.isV3) {
    return this.quoter.methods.getAmountsOut(amountsIn, packedPath);
  } else {
    return this.quoter.methods.quoteExactInput(packedPath,amountsIn);
  } 
},
}
