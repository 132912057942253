<template>
 <div :connected="connected" :state="state" class="consoleview" :class="[classPlatform, classTablet]">
  <console-header-button-bar />
  <div class="current-block" :class="[classPlatform]">
    <text-comp class="block" small="force" :class="[classPlatform]" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap"
    :text="computedShowBlockWarning?blockWarningMessage:`CURRENT BLOCK: ${block}`"
    :color="computedShowBlockWarning?states.TextStatesStyles.BaseText.Color.dark:states.TextStatesStyles.BaseText.Color.default"
    align="right"/>
  </div>
  <div class="content-holder" :class="[classPlatform]">
    <div class="content-view" :class="[classPlatform]">
      <div class="content-area" :class="[classPlatform]">
        <div id="logsMain" class="scrollable-holder" :class="[classPlatform]">
          <div class="content" :class="[classPlatform]">
            <text-comp v-if="computedMaintenance" align="left" text="Servers is on maintenance. ETA 5-6 hours." :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.red" />
            <text-comp br align="left" text="WELCOME IN DEF.CAFE FAST TRADING TERMINAL!" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp br align="left" text=".../README" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp align="left" text="THIS IS CONSOLE FIELD." :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp align="left" text="1) CONNECT YOUR WALLET AND MANAGE TRADING WALLETS IN WALLET MANAGER" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp align="left" text="2) PUT CA IN SEARCH FIELD" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp br align="left" text="3) ENJOY YOUR TRADING SESSION" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
            <text-comp br align="left" text="ASK US ANY QUESTIONS IN PUBLIC TELEGRAM GROUP" :wrap="states.TextStatesStyles.BaseText.Wrap.wrap" :color="states.TextStatesStyles.BaseText.Color.default" />
            <span v-for="(item,index) in computedLogs" :key="item.toString()+index">
              <text-comp
              :color="states.TextStatesStyles.BaseText.Color[logsClassConvertionToColor[logsClass[index]]]"
              :text="toUpperCase(item)"
              align="left"
              :wrap="states.TextStatesStyles.BaseText.Wrap.wrap"
              :br="!computedResults[index]?true:null" />
              <order-history v-if="computedResults[index]&&computedResults[index].type=='trade-output'"
              :state="states.OrderHistory.Type.manual"
              :item="computedResults[index]"
              :index="index" />
              <order-market v-if="computedResults[index]&&computedResults[index].type=='market-trade'"
              :key="computedResults[index].uuid.toString()+mtRerenderer"
              :state="computedResults[index].trade.settings.trade.sell?states.OrderRunning.LimitType.sell:states.OrderRunning.LimitType.buy"
              :item="computedResults[index]"
              :index="index" />
              <search-element v-if="computedResults[index]&&computedResults[index].type=='search'"
              :key="'search'+Math.random().toString()+mtRerenderer"
              :item="computedResults[index]"
              :index="index" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import TextComp from '../basic/TextComp.vue'
import OrderHistory from '../basic/OrderHistory.vue'
import OrderMarket from '../basic/OrderMarket.vue'
import SearchElement from '../basic/SearchElement.vue'

import ConsoleHeaderButtonBar from '../bricks/ConsoleHeaderButtonBar.vue'

export default {
  name: 'ConsoleView',
  components: {
    TextComp,
    OrderHistory,
    ConsoleHeaderButtonBar,
    OrderMarket,
    SearchElement
  },
  inject: injects.getInjects(),
  props: ["state","connected"],
  data () {
    return {
      states: states
    }
  },
  computed: {
    isConnected() {
      return this.connected != null;
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.consoleview {
  align-items: flex-start;
  border: 2px solid;
  border-color: var(--sepia-skin);
  border-radius: 4px 4px 4px 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.consoleview.desktop {
  flex: 1 1 608px;
  min-height: 608px;
}
.consoleview.mobile {
  flex: 1 1 116px;
  min-height: 116px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0px solid;
}
.consoleview.tablet {
  flex: 1 1 408px;
  min-height: 408px;
  border-radius: 4px 4px 4px 4px;
  border-bottom: 2px solid;
  border-color: var(--sepia-skin);
}
.consoleview .current-block {
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: flex-end;
  align-self: stretch;
}
.consoleview .current-block.desktop {
  padding: 8px 14px 0px;
}
.consoleview .current-block.mobile {
  padding: 2px 10px 2px 10px;
}
.consoleview .current-block .block {
}
.consoleview .current-block .block.desktop {
  font-size: var(--font-size-m) !important;
}
.consoleview .current-block .block.mobile {
}
.consoleview .content-holder {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  align-self: stretch;
  overflow: hidden;
}
.consoleview .content-holder.desktop {
  gap: 15px;
  padding: 8px 14px 16px;
}
.consoleview .content-holder.mobile {
  gap: 10px;
  padding: 0px 12px 10px 12px;
}
.consoleview .content-holder .content-view {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  gap: 10px;
  padding: 0px 0px 0px 0px;
}
.consoleview .content-holder .content-view .content-area {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.consoleview .content-holder .content-view .content-area.desktop {
  gap: 15px;
}
.consoleview .content-holder .content-view .content-area.mobile {
  gap: 8px;
}
.consoleview .content-holder .content-view .content-area .scrollable-holder {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
}
.consoleview .content-holder .content-view .content-area .scrollable-holder.desktop {
  gap: 15px;
}
.consoleview .content-holder .content-view .content-area .scrollable-holder.mobile {
  gap: 8px;
}
.consoleview .content-holder .content-view .content-area .scrollable-holder .content {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  align-items: stretch;
  width: 100%;
}
.consoleview .content-holder .content-view .content-area .scrollable-holder .content.desktop {
  padding-right: 15px;
}
.consoleview .content-holder .content-view .content-area .scrollable-holder .content.mobile {
  padding-right: 8px;
}
</style>
