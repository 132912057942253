<template>
  <main-screen :connected="this.connected?true:null" />
</template>
<script>
/* eslint-disable */
const injects = require('../modules/injects')
import MainScreen from './screens/MainScreen.vue'

export default {
  name: 'RouterMain',
  components: {
    MainScreen
  },
  inject: injects.getInjects()
}
</script>
