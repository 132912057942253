/* eslint-disable */
const  { EthereumClient, w3mConnectors, w3mProvider } = require('@web3modal/ethereum')
const  { Web3Modal } = require('@web3modal/html')
const  { configureChains, createConfig } = require('@wagmi/core')
const  { arbitrum, mainnet, polygon } = require('@wagmi/core/chains')
const  { getAccount, watchAccount, connect, disconnect, signMessage} = require('@wagmi/core')
const chains = [mainnet]
const projectId = 'a06fe95642c80b70c30f899c1278b003'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)
const web3modal = new Web3Modal({ projectId }, ethereumClient);

/*useAccount({
  onConnect({ address, connector, isReconnected }) {
    console.log('Connected', { address, connector, isReconnected })
  },
})*/
//web3modal.subsribeModal((modal) => {console.log(modal)});
const account = getAccount();
module.exports = {
  vmcomponent: null,
  selectedAccount: null,
  watch: null,
  connected: false,
  init: function(vmcomponent) {
    watchAccount(async (account) => {
      if(account.status == 'connected') {
        this.vmcomponent.disconnect();
        this.connected = true;
        this.selectedAccount = account.address;
        await this.fetchAccountData();
      }
    })
    this.vmcomponent = vmcomponent;
  },
  fetchAccountData:async function () {
    this.vmcomponent.connect(this.selectedAccount);
    this.vmcomponent.providerConnected();
    this.vmcomponent.generateNonce(this.selectedAccount);
  },
  AuthenticateWithWallet: async function(nonce) {
  try {
    const message = await signMessage({
      message: `I am signing my one-time nonce: ${nonce}`,
    });
  if(message) this.vmcomponent.authenticateWithSign(this.selectedAccount, nonce, message);
  else this.Disconnect();
  } catch(e) {
    this.Disconnect();
  }
  }, 
  Disconnect: async function () {
    disconnect();
    this.connected = false;
    web3modal.closeModal();
    this.vmcomponent.disconnect();
    this.vmcomponent.providerDisconnect();
  },
  onConnect: async function () {

  if(!this.connected) {
    try {
      web3modal.openModal();
    } catch(e) {
    }
  } else {
    this.Disconnect();
  }
}
}