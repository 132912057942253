<template>
 <div class="flagrectangle" :class="classPlatform">
  <div class="flex-col flex-col-4" :class="classPlatform">
    <div class="rectangle-3" :class="classPlatform"></div>
    <div class="rectangle-33" :class="classPlatform"></div>
    <div class="rectangle-3" :class="classPlatform"></div>
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')

export default {
  name: 'FlagRectangle',
  components: {
  },
  inject: injects.getInjects(),
  props: [],
}
</script>
<style scoped>
.flagrectangle {
  position: relative;
  width: 100%;
}

.flagrectangle.mobile {
  height: 64px;
}
.flagrectangle.desktop {
  height: 84px; 
}
.flagrectangle.absolute {
  position: absolute;
}
.flex-col {
  width: 100%;
}
.flex-col.mobile {
  gap: 6px;
}
.flex-col.desktop {
  gap: 12px;
  min-height: 84px;
}
.flex-col-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.rectangle-3 {
  background-color: var(--sepia-skin);
  width: 100%;
}
.rectangle-3.mobile {
  height: 14px;
}
.rectangle-3.desktop {
  height: 20px;
}
.rectangle-33 {
  background-color: var(--irish-coffee);
  width:100%;
}
.rectangle-33.mobile {
  height: 14px;
}
.rectangle-33.desktop {
  height: 20px;
}
</style>
