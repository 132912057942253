/* eslint-disable */
module.exports = {
  data: ['botOn',
  'address',
  'amount',
  'sellamount',
  'getWalletAddressOrTag',
  'setLimitOrderRetry',
  'key',
  'gas',
  'freeUpgradeAvailable',
  'logs',
  'socket',
  'logsArray',
  'renameKey',
  'bridge',
  'withdrawalsArray',
  'eligibleData',
  'logsClassArray',
  'reRenderBridge',
  'logsClassConvertionToColor',
  'logsClass',
  'changeSnipeView',
  'showActiveSnipes',
  'alertBlock',
  'switchBridgeDestination',
  'bridgeToETH',
  'logsTxHasArray',
  'block',
  'importWalletShow',
  'nextWithdrawStage',
  'showWalletWarning',
  'baseWithdrawValue',
  'walletWarningMessage',
  'walletsArray',
  'deletedWalletsExists',
  'defaultButtonWalletClass',
  'defaultButtonWalletImportClass',
  'activeWalletClass',
  'defaultTrashClass',
  'activeTrashClass',
  'showTrash',
  'inputImportKey',
  'walletWarningTimeout',
  'blockWarningTimeout',
  'reRender',
  'reRenderUse',
  'classMarginLeft',
  'classBLLaunches',
  'blockWarningMessage',
  'currentScreen',
  'walletForDeletion',
  'maintenance',
  'currentWallet',
  'currentToken',
  'resultArray',
  'ordersArray',
  'tSL',
  'balUpdateTimeout',
  'showOrdersHistory',
  'autoTrades',
  'isPanelBuyingNow',
  'buyPercentage',
  'useSL',
  'useTP',
  'useBP',
  'stopLoss',
  'takeProfit',
  'currentValues',
  'favorites',
  'quickWarn',
  'quickWarnTimeout',
  'connected',
  'botSwapFocus',
  'settingsJSMounted',
  'scrollScriptSettings',
  'settingsGas',
  'settingsSlippage',
  'txCost',
  'approved',
  'chain',
  'currentSwap',
  'reRenderChart',
  'atRerenderer',
  'currentWalletOptions',
  'secretWallets',
  'swPage',
  'secretWalletSelected',
  'hPage',
  'currentRename',
  'renameValue',
  'priceTriggerAmount',
  'buyingPriceMultiplyer',
  'USDPrice',
  'WETHPrice',
  'MCAP',
  'LP',
  'root',
  'dark',
  'svgBG',
  'svgCC',
  'svgBORD',
  'styleChanged',
  'currentBlockData',
  'settingsActive',
  'windowHeight',
  'prevChain',
  'providerConnect',
  'gasPreset',
  'minBalToSend',
  'useMEV',
  'marketTrades',
  'showMarketOrders',
  'exportedKey',
  'disableRetry'],
  computed: ['computedBotOn',
  'computedConnected',
  'computedTokenInFavorites',
  'computedShowBlockWarning',
  'computedMaintenance',
  'computedLogs',
  'computedResults',
  'computedShowCreateButton',
  'computedShowImportButton',
  'computedShowImportWallet',
  'computedShowTrashClass',
  'computedShowOrdersHistory',
  'computedWallets',
  'computedWETH',
  'computedSecretWallets',
  'computedCutOrders',
  'computedIsProduction',
  'computedCurrentWalletLabel',
  'computedIsPanelBuying',
  'computedUseBP',
  'computedUseTP',
  'computedUseSL',
  'computedtSL',
  'computedRoute',
  'computedBPAmount',
  'computedTxHash',
  'computedShowWalletWarning',
  'computedSWPage',
  'computedOrders',
  'computedScreenSize',
  'classPlatform',
  'classPlatformTabletOverride',
  'classTablet',
  'computedUseMev',
  'isMevProtection',
  'mtRerenderer',
  'computedShowMarketOrders',
  'computedDisableRetry'],
  methods: ['toUpperCase',
  'tokenInFavoritesUsed',
  'search',
  'isBNB',
  'clearSearch',
  'addToFavorites',
  'showLogSection',
  'switchLogSection',
  'useDirectPairOnStable',
  'deleteWallet',
  'cancelDeleteWallet',
  'createWallet',
  'showImportField',
  'switchWalletView',
  'importWallet',
  'updateWalletUse',
  'showEmpty',
  'showWalletRow',
  'renameActive',
  'copyAddress',
  'hideRename',
  'showOptionsPopUp',
  'isShowOptionsPopUp',
  'hideOptionsPopUp',
  'copyKey',
  'renameWallet',
  'confirmDeleteWallet',
  'selectSecretWallet',
  'canChangeSWPage',
  'changeSWPage',
  'clearSecret',
  'importSecretWallet',
  'changeOrdersLog',
  'execute',
  'getFloatWithDoublePrecision',
  'isPositiveValue',
  'calculateBPPercentage',
  'getBNBFromWei',
  'isblProtection',
  'isBlacklisted',
  'blProtection',
  'autoOrderAction',
  'getOrderAction',
  'timeConverter',
  'canChangeHPage',
  'changeHPage',
  'isChainSelected',
  'switchChain',
  'fillQuick',
  'amountHandler',
  'sellamountHandler',
  'focusHandler',
  'isDefaultSetting',
  'setDefaultSetting',
  'trade',
  'approveLabel',
  'switchPanelBuying',
  'changeBP',
  'amountCheck',
  'amountBPFocusLost',
  'changeTP',
  'amountTPFocusLost',
  'changeSL',
  'changetSL',
  'computeShowExecuteButton',
  'computeShowBlacklistProtection',
  'computeShowLimitButton',
  'copyTxHash',
  'useUSDPrice',
  'switchStyle',
  'inputFieldHandler',
  'showSettingsPopup',
  'hideSettingsPopup',
  'setLimits',
  'exportKey',
  'setLimitOrderSlippage',
  'setLimitOrderGas',
  'fromBNtoString',
  'LoginManagerConnect',
  'setGasPreset',
  'changeMEV',
  'setLimitOrderProtectons',
  'changeDisableRetry',
  'showProfilePopup',
  'hideProfilePopup',
  'profileActive',
  'user',
  'upgradeTier',
  'cafe',
  'subscribe',
  'usrReRenderer',
  'referralData',
  'copyRefLink',
  'refEarnings',
  'searchInProgress',
  'pasteToSearchbar',
  'hideHelpPopup',
  'helpActive',
  'showHelpPopup'
],
  getInjects: function() {
    return this.data.concat(this.computed).concat(this.methods);
}
}
