<template>
 <div :active="active" :state="state" :status="status" class="mainviewcontentheader" :class="[classPlatform]">
  <div class="innerholder" :class="[classPlatform]">
    <div class="header" :class="[classPlatform]">
      <button-transparent v-show="isWallets&&computedShowCreateButton" text="create" :state="states.ButtonTransparentStates.tertiary" @click="createWallet()"/>
      <button-transparent v-show="isWallets&&computedShowImportButton" :active="computedShowImportWallet?true:null" text="import" :state="states.ButtonTransparentStates.tertiary"  @click="showImportField()"/>
      <button-transparent v-show="isOrders"
      :active="computedShowOrdersHistory?true:null"
      text="history"
      :state="states.ButtonTransparentStates.tertiary"
      @click="changeOrdersLog(true, false, false)" />
       <button-transparent v-show="isOrders"
      :active="!computedShowOrdersHistory&&!computedShowMarketOrders?true:null"
      text="limit orders"
      :state="states.ButtonTransparentStates.tertiary"
      @click="changeOrdersLog(false, true, false)" />
      <withdraw-row v-show="isBase" :state="states.MainViewStatus.Content.WalletRow.WithdrawBase" />

    </div>
    <div v-if="isWallets&&!isWalletsDefault" class="content">
      <text-comp v-if="isWalletsImportShowField" text="private key / seed phrase:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <text-comp v-if="isWalletsImportSelect" text="click on wallet for import:" :wrap="states.TextStatesStyles.BaseText.Wrap.full" :color="states.TextStatesStyles.BaseText.Color.default" />
      <div v-if="isWalletsImportShowField" class="inner-content" :class="[classPlatform]">
        <input-comp mode="password" placeh="" class="keyinput"
        :class="inputClass"
        :name="'inputImportKey'"
        :value="inputImportKey"
        @inputHandler="inputFieldHandler"
        />
        <button-transparent text="ok" :state="states.ButtonTransparentStates.tertiary" @click="importWallet()"/>
      </div>
    </div>
  </div>
 </div>
</template>
<script>
/* eslint-disable */
/*
   <button-transparent v-show="isOrders"
      :active="!computedShowOrdersHistory&&computedShowMarketOrders?true:null"
      text="market"
      :state="states.ButtonTransparentStates.tertiary"
      @click="changeOrdersLog(false, false, true)" />
      */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import TextComp from '../basic/TextComp.vue'
import ButtonTransparent from '../basic/ButtonTransparent.vue';
import InputComp from '../basic/InputComp.vue';
import WithdrawRow from './WithdrawRow.vue';
export default {
  name: 'MainViewContentHeader',
  components: {
    ButtonTransparent,TextComp,
    InputComp, WithdrawRow
  },
  inject: injects.getInjects(),
  props: ["active","state","status"],
  data () {
    return {
      states: states,
      mode: this.status
    }
  },
  computed: {
    isBase() {
      return this.state == states.MainViewStatus.View.Base;
    },
    isOrders() {
      return this.state == states.MainViewStatus.Content.Header.Orders.name;
    },
    isWallets() {
      return this.state == states.MainViewStatus.Content.Header.Wallets.name;
    },
    isOrdersDefault() {
      return this.status == states.MainViewStatus.Content.Header.Orders.Default;
    },
    isWalletsDefault() {
      return this.status == states.MainViewStatus.Content.Header.Wallets.Default;
    },
    isWalletsImportSelect() {
      return this.status == states.MainViewStatus.Content.Header.Wallets.ImportSelect;
    },
    isWalletsImportShowField() {
      return this.status == states.MainViewStatus.Content.Header.Wallets.ImportShowField;
    },
    isWalletsDeleted() {
      return this.status == states.MainViewStatus.Content.Header.Wallets.Deleted;
    }, 
    inputClass() {
      return this.classPlatform.desktop?states.TextStatesStyles.BaseText.desktop.default:states.TextStatesStyles.BaseText.mobile.default;
    }   
  },
  methods: {
    testChange() {
      if(this.mode != states.MainViewStatus.Content.Header.Wallets.Deleted) this.mode = states.MainViewStatus.Content.Header.Wallets.Deleted;
      else this.mode = states.MainViewStatus.Content.Header.Wallets.Default;
    }
  }
}
</script>
<style scoped>
.mainviewcontentheader {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}
.mainviewcontentheader .innerholder {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  position: relative;
  position: relative;
}
.mainviewcontentheader .innerholder.mobile {
  gap: 10px;
}
.mainviewcontentheader .innerholder.desktop {
  gap: 24px;
}
.mainviewcontentheader .innerholder .header {
  align-items: center;
  align-self: stretch;
  gap: 17px;
  justify-content: flex-end;
  padding: 0px 0px 0px 0px;
  display: flex;
  position: relative;
}
.mainviewcontentheader .innerholder .header.mobile {
  align-items: flex-start
}
.mainviewcontentheader .innerholder .content {
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-self: stretch;
  display: flex;
  position: relative;
}
.mainviewcontentheader .innerholder .content .inner-content {
  align-items: center;
  gap: 6px;
  align-self: stretch;
  display: flex;
  position: relative;
}
.mainviewcontentheader .innerholder .content .inner-content.mobile {
  padding: 0px 3px;
}
.mainviewcontentheader .innerholder .content .inner-content.desktop {
  padding: 0px 6px;
}
.mainviewcontentheader .innerholder .content .keyinput {
  border: 1px solid var(--sepia-skin);
  border-radius: 2px;
  max-width: 50%;
}
.wbase {

}
</style>
