<template>
  <input :name="name"
          :value="!this.lock?value:'searching...'"
          :chainEvent="chainEvent"
          :chainEventArgs="chainEventArgs"
          :mode="mode"
          :placeh="placeh"
          :type="mode"
          :placeholder="placeh"
          class="inputcomp"
          :disabled="this.lock"
          :class="[classPlatform,this.wrap?this.wrap:'',this.lock?'lock':'', classColor]"
          v-click-out-side="clickoutside?this.hideThis:()=>''"
          @input="!this.lock?$emit('inputHandler',
                        $event.target.value,
                        name,
                        chainEvent,
                        chainEventArgs != null || chainEventArgs != undefined ? chainEventArgs : null):null" />
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')
import clickOutSide from "@mahdikhashan/vue3-click-outside";

export default {
  name: 'InputComp',
  directives: {
    clickOutSide,
  },
  components: {
  },
  inject: injects.getInjects(),
  props: ["mode","placeh","value","name","chainEvent","chainEventArgs","clickoutside","wrap","lock"],
  emits:['inputHandler'],
  data() {
    return {
    states: states,
    clicks: 0,
    }
  },
  computed: {
    classColor() {
      return this.lock?'smaller-text dark':'';
    },
  },
  methods: {
    hideThis(e) {
      this.clicks++;
      if(this.clicks > 0) {
        this.clicks = 0;
        this[this.clickoutside](e);
      }
    }
  }
}
</script>
<style scoped>
.inputcomp {
  position: relative;
  display: flex;
  background-color: var(--bon-jour);
  border: 0px solid;
  height: 100%;
  width: 100%;
}
.inputcomp.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inputcomp.lock {
  pointer-events: none;
}
.smaller-text.dark {
  color: var(--irish-coffee);
}
</style>
