<template>
 <div class="search-bar" :class="[classPlatform, snipeClass]">
  <div v-if="!isSnipe" class="col1" :class="classPlatform">
    <div>
      <icon-comp :state="states.IconTypes.star" :active="computedTokenInFavorites?true:null" @click="!isGlobalSnipe?addToFavorites():alertBlock('Exit launch buyer mode to be able to use this function',2000)"/>
    </div>
  </div>
  <div class="col3" :class="classPlatform">
    <input-comp mode="text" placeh="enter token address" class="searchinput"
    :class="inputClass"
    :wrap="'truncated'"
    :name="'address'"
    :value="address"
    :lock="searchInProgress"
    @inputHandler="inputFieldHandler"
     />
  </div>
  <div class="col2" :class="classPlatform">
    <icon-comp :state="states.IconTypes.eraseexpand" @click="pasteToSearchbar()"/>
    <icon-comp v-if="!isSnipe" :state="states.IconTypes.search" :active="computedBotOn?true:null" @click="!isGlobalSnipe?search():alertBlock('Exit launch buyer mode to be able to search tokens',2000)"/>
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')
import IconComp from './IconComp.vue'
import InputComp from './InputComp.vue'


export default {
  name: 'SearchBar',
  components: {
    IconComp,
    InputComp
  },
  inject: injects.getInjects(),
  props: ["state"],
  data() {
    return {
    states: states    
    }
  },
  computed: {
    isSnipe() {
      return this.state == 'snipe';
    },
    snipeClass() {
      return this.state == 'snipe'?'snipe':''
    },
    isGlobalSnipe() {
      return this.showLogSection('snipe');
    },
    inputClass() {
      return this.classPlatform.desktop?states.TextStatesStyles.InputSearch.desktop:
      this.classPlatform.mobile?states.TextStatesStyles.InputSearch.mobile:'';
    }
  }
}
</script>
<style scoped>
.search-bar {
  border: 1px solid;
  border-color: var(--sepia-skin);
  border-radius: 4px;
  box-shadow: 1px 1px 0px var(--sepia-skin-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex: 1;
}
.search-bar.mobile {
  height: 24px;
  padding: 0px 8px 0px 15px;
}
.search-bar.desktop {
  height: 32px;
  padding: 0px 11px 0px 14px;
}
.search-bar.absolute {
  position: absolute;
}
.search-bar.snipe{
  border: 0px solid var(--sepia-skin);
  border-radius: 0px;
  box-shadow: 0px 2px 0px var(--sepia-skin-2);
  padding-left: 0px;
  width:100%;
}
.search-bar .col1 {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  border-color: var(--sepia-skin);
  border-right: 1px solid var(--sepia-skin);
}
.col1.mobile {
  gap: 14px;
  height: 24px;
  min-width: 30px;
}
.col1.desktop {
  gap: 11px;
  height: 32px;
  min-width: 31px;
}
.search-bar .col3 {
  display: flex;
  position: relative;
  align-content: flex-start;
  justify-content: flex-start;
  flex: 1 1 auto;
}
.search-bar .col3.mobile {
  height: 22px;
}
.search-bar .col3.desktop {
  height: 30px;
}
.search-bar .col3 .searchinput.mobile {
  padding: 0px 6px 0px;
  }
.search-bar .col3 .searchinput.desktop {
  padding: 0px 10px 0px;
}
.col2 {
  display: flex;
  justify-content: flex-end;
  position: relative;
  flex-wrap: wrap;
}
.col2.mobile {
  gap: 10px;
  min-width: 37px;
  display: flex;
  align-items: center;
}
.col2.desktop {
  gap: 4px;
  min-width: 44px;
}
</style>
