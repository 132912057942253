<template>
 <div :active="active" class="consoleheaderbuttonbar" :class="[classPlatform]">
  <window-bar absolute />
  <div class="buttons" :class="[classPlatform]">
    <icon-comp :state="states.IconTypes.starfilled" active />
    <button-transparent v-show="this.connected"
    v-for="(item) in this.favorites"
    :key="item.address.toString()+item.symbol.toString"
    :active="tokenInFavoritesUsed(item)?true:null"
    :chainIcon="item.chain"
    @click="search(item.address, true, item)"
    :text="item.symbol"
    :state="states.ButtonTransparentStates.favorites" />
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import ButtonTransparent from '../basic/ButtonTransparent.vue';
import IconComp from '../basic/IconComp.vue';
import WindowBar from '../basic/WindowBar.vue';
export default {
  name: 'ConsoleHeaderButtonBar',
  components: {
    ButtonTransparent,WindowBar,IconComp
  },
  inject: injects.getInjects(),
  props: ["active"],
  data () {
    return {
      states:states    
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.consoleheaderbuttonbar {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.consoleheaderbuttonbar.desktop {
  height: 28px;
}
.consoleheaderbuttonbar.mobile {
  height: 20px;
  padding-bottom: 2px;
}
.consoleheaderbuttonbar .buttons {
  align-items: center;
  display: flex;
  position: relative;
}
.consoleheaderbuttonbar .buttons.desktop {
  gap: 10px;
  padding: 0px 10px;
}
.consoleheaderbuttonbar .buttons.mobile {
  gap: 6px;
  padding: 0px 8px;
}
</style>
