<template>
 <div :active="active" :warning="warning" class="popupheader" :class="[classPlatform]">
  <window-bar absolute />
  <div class="buttons" :class="[classPlatform]">
    <icon-comp :state="states.IconTypes.close" light @click="warning != null? cancelDeleteWallet() : (hideProfilePopup(),hideSettingsPopup(),hideHelpPopup())" />
  </div>
 </div>
</template>
<script>
/* eslint-disable */
const injects = require('../../modules/injects')
const states = require('../../modules/states')

import IconComp from '../basic/IconComp.vue';
import WindowBar from '../basic/WindowBar.vue';
export default {
  name: 'PopupHeader',
  components: {
    WindowBar,IconComp,
  },
  inject: injects.getInjects(),
  props: ["active", "warning"],
  data () {
    return {
      states:states    
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.believers {
  position: fixed;
  z-index: 10;
  transform: translate(-35%, -30%) rotate(-20deg);
}
.believers.desktop {
}
.believers.mobile {
}
.popupheader {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.popupheader.desktop {
  height: 32px;
}
.popupheader.mobile {
  height: 24px;
}
.popupheader .buttons {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.popupheader .buttons.desktop {
  padding: 0px 6px;
}
.popupheader .buttons.mobile {
  padding: 0px 4px;
}
</style>
